import React, { useContext, useEffect, useState } from 'react';
import { useAuth } from '../AuthProvider';
import AlertContext from '../../modules/Modal/AlertModal/AlertContext';
import AuthService from '../AuthService';
import { useNavigate } from 'react-router-dom';
import MemberService from '../MemberService';

const CancelAffiliateServiceViewModel =  {
    SetDisplayStatusHook : () => {
        const navigate = useNavigate()
        const {alert: alertComp } = useContext(AlertContext);
        const [userData,setUserData] = useState({name:''})
        const [display, setValue] = useState({
            superbinMore : {
                display : false,
                idx : undefined,
                consent_at : ''
            },
            superbinRookie : {
                display: false,
                idx : undefined,
                consent_at : ''
            }
        });
        const getUserData = async() => {
            try{
                const result = await AuthService.Login()
                setUserData({
                    name : result.nickname
                })
                const serviceDisplayStatus = {...display}
                const more = await getMoreData()
                if(more){
                    serviceDisplayStatus.superbinMore = more
                }
                const rookie = await getRookieData()
                if(rookie?.display){
                    serviceDisplayStatus.superbinRookie = rookie
                }
                setValue(serviceDisplayStatus)
            }catch(err){
                await alertComp('오류 발생', '이용에 불편을 드려 죄송합니다. 서버 오류가 발생하였습니다. 계속해서 문제가 발생하는 경우 수퍼빈 카카오톡 채널로 문의 바랍니다.',undefined,'메인으로 돌아가기')
                navigate(-1)
            }
        }
        const getMoreData = async() => {
            let moreServiceStatus = {
                display : false,
                idx : undefined,
                consent_at : ''
            }
            try{
                const result = await MemberService.getMoreServiceData()
                if(result.is_used){
                    moreServiceStatus = {
                        display:true,
                        idx:result.member.id ?? '확인불가',
                        consent_at:result.member.certification_at ?? '확인불가'
                    }
                }
                return moreServiceStatus
            }catch(err){
                await alertComp('오류 발생', '이용에 불편을 드려 죄송합니다. 서버 오류가 발생하였습니다. 계속해서 문제가 발생하는 경우 수퍼빈 카카오톡 채널로 문의 바랍니다.',undefined,'메인으로 돌아가기')
                navigate(-1)
            }
        }
        const getRookieData = async() => {
            let rookieServiceStatus = {
                display: false,
                idx : undefined,
                consent_at : ''
            }
            try{
                const result = await MemberService.getRookieServiceData()
                if(result.terms){
                    result.terms.forEach((element) => {
                        if(element.terms_code === "001"){
                            rookieServiceStatus = {
                                display:true,
                                idx:element.idx,
                                consent_at:element.consent_at ?? '확인불가'
                            }
                        }
                    });
                }
                return rookieServiceStatus
            }catch(err){
                if(err.response?.status === 404){
                    console.error(err)
                }else{
                    await alertComp('오류 발생', '이용에 불편을 드려 죄송합니다. 서버 오류가 발생하였습니다. 계속해서 문제가 발생하는 경우 수퍼빈 카카오톡 채널로 문의 바랍니다.',undefined,'메인으로 돌아가기')
                    navigate(-1)
                }
            }
        }
        useEffect(()=>{
            getUserData()
            getRookieData()
            getMoreData()
        },[])
        return { userData,display };
    },
    SetWithdrawServiceHook : () => {
        const navigate = useNavigate()
        const {alert: alertComp } = useContext(AlertContext);
        const [superbinServiceStatus, setSuperbinService] = useState({
            superbinMoreDisabled : false,
            superbinRookieDisabled : false
        });
        useEffect(()=>{
            const serviceStatus = {            
                superbinMoreDisabled : false,
                superbinRookieDisabled : false
            }
            setSuperbinService(serviceStatus)
        },[])
        const setSuperbinMoreStatus = async(idx) => {
            try{
                const result = await MemberService.deleteMoreService()
                const superbinSerivceData = {...superbinServiceStatus}
                superbinSerivceData.superbinMoreDisabled = true
                setSuperbinService(superbinSerivceData)
            }catch(err){
                await alertComp('오류 발생', '이용에 불편을 드려 죄송합니다. 서버 오류가 발생하였습니다. 계속해서 문제가 발생하는 경우 수퍼빈 카카오톡 채널로 문의 바랍니다.',undefined,'메인으로 돌아가기')
                navigate(-1)
            }
        };
        const setSuperbinRookieStatus = async(idx) => {
            try{
                const result = await MemberService.deleteRookieService()
                const superbinSerivceData = {...superbinServiceStatus}
                superbinSerivceData.superbinRookieDisabled = true
                setSuperbinService(superbinSerivceData)
            }catch(err){
                await alertComp('오류 발생', '이용에 불편을 드려 죄송합니다. 서버 오류가 발생하였습니다. 계속해서 문제가 발생하는 경우 수퍼빈 카카오톡 채널로 문의 바랍니다.',undefined,'메인으로 돌아가기')
                navigate(-1)
            }

        };
        return { superbinServiceStatus, setSuperbinMoreStatus , setSuperbinRookieStatus };
    }
};

export default CancelAffiliateServiceViewModel;