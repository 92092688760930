import React from 'react';
import './JoinPager.css'
const JoinPager = ({num}) => {
    return (
        <div className='join-pager'>
            <div className={num === 1? "active":"non-active"}><p>1</p></div>
            <div className={num === 2? "active":"non-active"}><p>2</p></div>
            <div className={num === 3? "active":"non-active"}><p>3</p></div>
            <div className={num === 4? "active":"non-active"}><p>4</p></div>
        </div>
    );
};

export default JoinPager; 