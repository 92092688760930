import React from 'react';
import style from '../WithDrawModal.module.css'
const DualModal = ({title,btnTitle,btnTwoTitle,onOkClick,onCancel}) => {
    return (
        <>
        <div className={style["modal-background"]}></div>
        <div className={style["modal-wrap"]}>
              <div className={style["modal"]}>
                <p className={style["title"]}>{title}</p>
                <div className={style["bottom-btn"]}>
                  <button onClick={()=>{onOkClick()}}>{btnTitle}</button>
                  <button onClick={()=>{onCancel()}}>{btnTwoTitle}</button>
                </div>
              </div>

        </div>
        </>
    );
};

export default DualModal;