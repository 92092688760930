import React, { useState, useEffect } from "react";
import './Timer.css'
const Timer = ({endtimer,setStarted,restart}) => {
  const [minutes, setMinutes] = useState(parseInt(3));
  const [seconds, setSeconds] = useState(parseInt(0));
  useEffect(()=>{
    setStarted()
  },[])
  useEffect(()=>{
    setMinutes(3)
    setSeconds(0)
    setStarted()
  },[restart])
  useEffect(() => {
    const countdown = setInterval(() => {
      if (parseInt(seconds) > 0) {
        setSeconds(parseInt(seconds) - 1);
      }
      if (parseInt(seconds) === 0) {
        if (parseInt(minutes) === 0) {
          clearInterval(countdown);
        } else {
          setMinutes(parseInt(minutes) - 1);
          setSeconds(59);
        }
      }
      if(seconds == 0 && minutes == 0){
        endtimer()
      }
    }, 1000);
    return () => clearInterval(countdown);
  }, [minutes, seconds]);
  return (
    <div className="timer-page">
      {minutes || seconds ?
      <div>남은 시간 {minutes}:{seconds < 10 ? `0${seconds}` : seconds}</div>:
      <div>인증시간이 만료되었습니다.</div>
      }
    </div>
  );
};
export default Timer;