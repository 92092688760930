// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".button_telecom-btn__FiHGi{margin-right: 7px; flex: 1 1; white-space: nowrap; padding: 10px;}\n.button_telecom-btn__FiHGi{background-color: transparent; border: 1px solid #BDBDBD; border-radius: 6px; color: #9E9E9E; font-weight: 400; font-size: 16px; line-height: 19px;}\n.button_telecom-btn-true__bwWx1{background-color: rgba(57, 151, 254, 0.1); border: 1px solid #3997FE; border-radius: 6px; color: #3997FE; border-radius: 6px; font-weight: 700; font-size: 16px; line-height: 19px;}\n.button_telecom-btn-false__2TOkn{background-color: transparent; border: 1px solid #BDBDBD; border-radius: 6px; color: #9E9E9E; font-weight: 400; font-size: 16px; line-height: 19px;}", "",{"version":3,"sources":["webpack://./src/assets/layouts/button.module.css"],"names":[],"mappings":"AAAA,2BAAa,iBAAiB,EAAE,SAAO,EAAE,mBAAmB,EAAE,aAAa,CAAC;AAC5E,2BAAa,6BAA6B,EAAE,yBAAyB,EAAE,kBAAkB,EAAE,cAAc,EAAE,gBAAgB,EAAE,eAAe,EAAE,iBAAiB,CAAC;AAChK,gCAAkB,yCAAyC,EAAE,yBAAyB,EAAE,kBAAkB,EAAE,cAAc,EAAE,kBAAkB,EAAE,gBAAgB,EAAE,eAAe,EAAE,iBAAiB,CAAC;AACrM,iCAAmB,6BAA6B,EAAE,yBAAyB,EAAE,kBAAkB,EAAE,cAAc,EAAE,gBAAgB,EAAE,eAAe,EAAE,iBAAiB,CAAC","sourcesContent":[".telecom-btn{margin-right: 7px; flex: 1; white-space: nowrap; padding: 10px;}\n.telecom-btn{background-color: transparent; border: 1px solid #BDBDBD; border-radius: 6px; color: #9E9E9E; font-weight: 400; font-size: 16px; line-height: 19px;}\n.telecom-btn-true{background-color: rgba(57, 151, 254, 0.1); border: 1px solid #3997FE; border-radius: 6px; color: #3997FE; border-radius: 6px; font-weight: 700; font-size: 16px; line-height: 19px;}\n.telecom-btn-false{background-color: transparent; border: 1px solid #BDBDBD; border-radius: 6px; color: #9E9E9E; font-weight: 400; font-size: 16px; line-height: 19px;}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"telecom-btn": "button_telecom-btn__FiHGi",
	"telecom-btn-true": "button_telecom-btn-true__bwWx1",
	"telecom-btn-false": "button_telecom-btn-false__2TOkn"
};
export default ___CSS_LOADER_EXPORT___;
