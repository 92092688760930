import React, { useEffect, useState } from 'react';
import DefinitionService from '../../Auth/DefinitionService';
import HttpRequestHelper from '../../Helper/HttpRequestHelper';
import NoBank from '../../assets/icons/nobank_icon.png'
import './BanksModal.css'
const BankModal = ({closeBankModal , item}) => {
    const [bankList,setbankList] = useState([])
    const [bankItem,setBankItem] = useState({})
    const [tab,setTab] = useState(0)
    useEffect(()=>{
        getBankList()
    },[])
    const getBankList = async() =>{
        var body = {
            filter:{is_used:"is:1"},
            order:["idx"],
        }
        const result = await DefinitionService.getBanks(body)
        result.list.forEach((element) => {
             element.name = setBankName(element.name)
        });
        setbankList(result.list)
        // body = {
        //     filter:{is_used:"is:1"},
        //     order:["idx"],
        //     paging:{ offset: 0, limit: resultForTotalCnt.paging.total_cnt}
        // }
        // try{
        //     const result = await DefinitionService.getBanks(body)
        //     result.list.forEach((element) => {
        //         element.name = setBankName(element.name)
        //     });
        //     setbankList(result.list)
        // }catch(error){
        // }

    }
    const selectOver = (bank_item) => {
        item(bank_item)
        closeBankModal()
    }
    const setBankName = (name)=>{
        var modifyName = ""
        switch(name){
            //은행사
            case "국민은행": modifyName = "국민"; break;
            case "산업은행": modifyName = "산업"; break;
            case "기업은행": modifyName = "기업"; break;
            case "지역 농축협": modifyName = "농협"; break;
            case "NH농협은행": modifyName = "농협"; break;
            case "우리은행": modifyName = "우리"; break;
            case "SC제일은행": modifyName = "SC"; break;
            case "하나은행": modifyName = "하나"; break;
            case "케이뱅크": modifyName = "케이뱅크"; break;
            case "우체국": modifyName = "우체국"; break;
            case "한국씨티은행": modifyName = "한국씨티"; break;
            case "경남은행": modifyName = "경남"; break;
            case "광주은행": modifyName = "광주"; break;
            case "대구은행": modifyName = "대구"; break;
            case "부산은행": modifyName = "부산"; break;
            case "전북은행": modifyName = "전북"; break;
            case "제주은행": modifyName = "제주"; break;
            case "카카오뱅크": modifyName = "카카오뱅크"; break;
            case "케이뱅크": modifyName = "케이뱅크"; break;
            case "새마을금고": modifyName = "새마을금고"; break;
            case "신협": modifyName = "신협"; break;
            case "산업은행": modifyName = "산업"; break;
            case "수협은행": modifyName = "수협"; break;
            case "도이치은행": modifyName = "도이치"; break;
            case "BOA은행": modifyName = "BOA"; break;
            case "비엔피파리바은행": modifyName = "BNP"; break;
            case "중국공상은행": modifyName = "중국공상"; break;
            case "HSBC은행": modifyName = "HSBC"; break;
            case "제이피모간체이스은행": modifyName = "JP"; break;
            case "산림조합중앙회": modifyName = "산림조합"; break;
            case "저축은행": modifyName = "저축은행"; break;
            case "토스뱅크": modifyName = "토스뱅크"; break;
            //증권사
            case "현대차증권": modifyName = "현대차증권"; break;
            case "KB증권": modifyName = "KB증권"; break;
            case "SK증권": modifyName = "SK증권"; break;
            case "대신증권": modifyName = "대신증권"; break;
            case "DB금융투자": modifyName = "DB금융투자"; break;
            case "메리츠증권": modifyName = "메리츠증권"; break;
            case "부국증권": modifyName = "부국증권"; break;
            case "신영증권": modifyName = "신영증권"; break;
            case "유진투자증권": modifyName = "유진투자증권"; break;
            case "이베스트투자증권": modifyName = "이베스트"; break;
            case "케이프투자증권": modifyName = "케이프증권"; break;
            case "키움증권": modifyName = "키움증권"; break;
            case "한국포스증권": modifyName = "포스증권"; break;
            case "하나증권": modifyName = "하나증권"; break;
            case "신한금융투자": modifyName = "신한금융투자"; break;
            case "한화투자증권": modifyName = "한화금융투자"; break;
            default:modifyName = name; break;
        }
        return modifyName
    }
    return (
        <div className='bank-modal-background '>
        <div className='bank-modal-wrap'>
            <div>
                <div className="close">
                    <svg onClick={()=>{closeBankModal()}} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M5.40039 5.3999L18.6004 18.5999" stroke="#222222" strokeWidth="2"/>
                        <path d="M18.5996 5.3999L5.39961 18.5999" stroke="#222222" strokeWidth="2"/>
                    </svg>
                </div>
                <div className='bank-tab'>
                    <div className={tab == 0? "tab-active":"tab-non-active"}>
                        <button onClick={()=>{setTab(0)}}>은행</button>
                        <hr></hr>
                    </div>
                    <div className={tab == 1? "tab-active":"tab-non-active"}>
                        <button onClick={()=>{setTab(1)}}>증권사</button>
                        <hr></hr>
                    </div>
                </div>
                <div className='bank-contents'>
                <div className="bank-contents-box">
                    {bankList?.map((item) => {
                        if(tab == 0){
                            return(
                                <>
                                {item.type === "B" ?
                                <div className="bank-item" key={item.idx} onClick={() => {selectOver(item)}}>
                                    <div className="bank-icon-box">
                                    {item.logo_url? <img className="bank-icon" src={item.logo_url} width="22" height={22}></img>:
                                    <img src={NoBank}></img>}
                                    </div>
                                    <p>{item.name}</p>
                                </div>:<></>}
                                </>
                            )
                        }else if(tab == 1){
                            return(
                                <>
                                {item.type === "F" ?
                                <div className="bank-item" key={item.idx} onClick={() => {selectOver(item)}}>
                                    <div className="bank-icon-box">
                                    {item.logo_url? <img className="bank-icon" src={item.logo_url} width="22" height={22}></img>:<img src={NoBank}></img>}
                                    </div>
                                    <p>{item.name}</p>
                                </div>:<></>}
                                </>
                            )
                        }
                    })}
                </div>
            </div>
        </div>

        </div>
    </div>
    );
};

export default BankModal;