import React from 'react';
export const Svgs = {
    LogoImg : () => {

    }
};
export const WithDrawRookieIcon = () => {
    return (
        <svg width="48" height="48" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="60.0039" height="60.0039" rx="6" fill="#2999FF"/>
        <g clip-path="url(#clip0_10650_25435)">
            <rect x="30.8203" y="10.666" width="7.26562" height="5.50781" fill="#FA6F49"/>
            <path d="M30.0703 9.49023H29.927C29.3169 9.49023 28.8223 9.98483 28.8223 10.5949V20.644C28.8223 21.2541 29.3169 21.7487 29.927 21.7487H30.0703C30.6804 21.7487 31.175 21.2541 31.175 20.644V10.5949C31.175 9.98483 30.6804 9.49023 30.0703 9.49023Z" fill="#3F352A"/>
            <path d="M27.3587 38.2812H32.6334C32.9689 38.2812 33.2907 38.4145 33.5279 38.6517C33.7651 38.889 33.8984 39.2107 33.8984 39.5462V49.1639H26.0938V39.5462C26.0938 39.2107 26.227 38.889 26.4642 38.6517C26.7015 38.4145 27.0232 38.2812 27.3587 38.2812Z" fill="#E3DBE1"/>
            <path d="M29.9325 31.5389C31.7722 31.5389 33.2635 30.0476 33.2635 28.2079C33.2635 26.3683 31.7722 24.877 29.9325 24.877C28.0929 24.877 26.6016 26.3683 26.6016 28.2079C26.6016 30.0476 28.0929 31.5389 29.9325 31.5389Z" fill="white"/>
            <path d="M15.3276 37.7715H11.4527C10.9869 37.7715 10.6094 38.149 10.6094 38.6148V42.4897C10.6094 42.9554 10.9869 43.333 11.4527 43.333H15.3276C15.7933 43.333 16.1708 42.9554 16.1708 42.4897V38.6148C16.1708 38.149 15.7933 37.7715 15.3276 37.7715Z" fill="white"/>
            <path d="M49.2143 37.7715H45.3394C44.8736 37.7715 44.4961 38.149 44.4961 38.6148V42.4897C44.4961 42.9554 44.8736 43.333 45.3394 43.333H49.2143C49.68 43.333 50.0576 42.9554 50.0576 42.4897V38.6148C50.0576 38.149 49.68 37.7715 49.2143 37.7715Z" fill="white"/>
            <rect x="5.58984" y="45.8652" width="48.8155" height="3.36659" fill="#FC6F49"/>
            <path d="M5.58203 30.8203H20.0478V49.2208H5.58203V30.8203Z" fill="#3060D6"/>
            <path d="M39.8379 30.8203H54.4194V49.2208H39.8379V30.8203Z" fill="#3060D6"/>
            <path d="M19.9121 25.2392L30.0119 19.3477L40.1116 25.2392V49.2327H19.9121V25.2392Z" fill="#FFD770"/>
            <circle cx="30.005" cy="30.757" r="3.36637" fill="white"/>
            <rect x="10.2129" y="37.5332" width="5.04956" height="5.04956" fill="white"/>
            <rect x="44.6992" y="37.5332" width="5.04956" height="5.04956" fill="white"/>
        </g>
            <defs>
                <clipPath id="clip0_10650_25435">
                <rect width="55.5487" height="46.1749" fill="white" transform="translate(2.22852 3.04688)"/>
                </clipPath>
            </defs>
        </svg>
    );
};
export const WithDrawMoreIcon = () => {
    return (
        <svg width="48" height="48" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_10650_25462)">
            <rect width="60" height="60" rx="6" fill="#247AE6"/>
            <path d="M35.5122 11.9929L30.6778 16.8521C30.3365 17.1951 29.7792 17.1951 29.4379 16.8521L24.6035 11.9929C24.1153 11.5022 24.461 10.6641 25.1522 10.6641H34.9678C35.6547 10.6641 36.0004 11.5022 35.5165 11.9929H35.5122Z" fill="white"/>
            <path d="M9.72656 62.3505V40.6159C9.72656 29.4449 18.8286 20.3906 30.0586 20.3906C41.2886 20.3906 50.3906 29.4449 50.3906 40.6159V62.3505C50.3906 66.4762 42.6414 70.1562 30.0586 70.1562C13.4861 70.1562 9.72656 66.4762 9.72656 62.3505Z" fill="white"/>
            <path d="M27.5796 35.6992H24.5586V49.0789H27.5796V35.6992Z" fill="url(#paint0_linear_10650_25462)"/>
            <path d="M34.517 41.922C35.3053 40.9769 35.7716 39.7594 35.7551 38.4347C35.718 35.4963 33.2624 33.1562 30.324 33.1562H27.0967C25.6935 33.1562 24.5586 34.2912 24.5586 35.6943L27.5795 36.594C27.5795 36.3258 27.7942 36.1112 28.0624 36.1112H30.2786C31.3723 36.1112 32.3669 36.821 32.6516 37.8817C33.023 39.2642 32.0903 40.6426 30.8027 40.8613C30.5881 40.8985 30.4148 41.1172 30.4148 41.3359V43.2963C30.4148 43.5645 30.6294 43.7791 30.8976 43.7791H31.1989C32.7878 43.7791 34.0424 45.302 33.7329 46.9321C33.4894 48.1909 32.3462 49.0782 31.0627 49.0782H24.5627L27.1627 51.6163C27.4351 51.8804 27.7983 52.029 28.178 52.029H31.104C34.2199 52.029 36.8364 49.4785 36.7992 46.3585C36.7786 44.5385 35.8872 42.9248 34.5211 41.9178L34.517 41.922Z" fill="#247AE6"/>
            <path d="M31.542 31.003L27.9309 31.8037C27.6172 31.8738 27.3242 31.6345 27.3242 31.3167V29.4472C27.3242 29.1541 27.5264 28.9024 27.8153 28.8364L31.4264 28.0357C31.7401 27.9656 32.0331 28.2049 32.0331 28.5227V30.3922C32.0331 30.6853 31.8309 30.937 31.542 31.003Z" fill="#247AE6"/>
            <path opacity="0.4" d="M53.6968 34.2852H6.17578V64.7894H53.6968V34.2852Z" fill="url(#paint1_linear_10650_25462)"/>
        </g>
        <defs>
        <linearGradient id="paint0_linear_10650_25462" x1="26.0691" y1="49.0088" x2="26.0691" y2="35.9799" gradientUnits="userSpaceOnUse">
            <stop stop-color="#247AE6" stop-opacity="0"/>
            <stop offset="0.24" stop-color="#247AE6"/>
        </linearGradient>
        <linearGradient id="paint1_linear_10650_25462" x1="29.9396" y1="34.3843" x2="29.9396" y2="64.5134" gradientUnits="userSpaceOnUse">
            <stop offset="0.01" stop-color="#1370E0" stop-opacity="0"/>
            <stop offset="0.1" stop-color="#1370E0" stop-opacity="0.04"/>
            <stop offset="0.24" stop-color="#1571E0" stop-opacity="0.13"/>
            <stop offset="0.42" stop-color="#1772E1" stop-opacity="0.29"/>
            <stop offset="0.62" stop-color="#1B75E3" stop-opacity="0.51"/>
            <stop offset="0.85" stop-color="#2077E4" stop-opacity="0.79"/>
            <stop offset="1" stop-color="#247AE6"/>
        </linearGradient>
        <clipPath id="clip0_10650_25462">
            <rect width="60" height="60" rx="6" fill="white"/>
        </clipPath>
        </defs>
    </svg>
    );
};