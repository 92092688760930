import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Modal from '../../../Layout/Modals/Modal';
import availability_disable from '../../../assets/icons/availability_disable.svg'
import availability_enable from '../../../assets/icons/availability_enable.svg'
import JoinPager from '../../../Layout/JoinPager/JoinPager';

const JoinPassCheck = () => {
    const navigate = useNavigate()
    const location = useLocation()
    const [passValue,setpassValue] = useState('')
    const [passEqualCheck,setPassEqualCheck] = useState(false)
    //알림 모달
    const [modal,setModal] = useState(false)
    const [modal_title,setmodal_title] = useState('')
    const [modal_contents,setmodal_contents] = useState('')

    const closeModalView = () => {
            setModal(false)
    }
    const setPassword = (data) => {
        checkPassValue(data)
        setpassValue(data)
    }
    const checkPassValue = (data) => {
        if(data === location.state.password){
            setPassEqualCheck(true)
        }else{
            setPassEqualCheck(false)
        }
    }
    const goToNext = () => {
        if(location.state.guardion_name){
            navigate("../sex",{state:{
                id : location.state.id,
                password:passValue,
                name : location.state.name,
                birthday:location.state.birthday,
                phone:location.state.phone,
                cicode : location.state.cicode,
                dicode : location.state.dicode,
                guardion_cicode : location.state.guardion_cicode,
                guardion_dicode : location.state.guardion_dicode,
                guardion_name:location.state.guardion_name,
                guardion_birthday : location.state.guardion_birthday,
            },replace:true})
        }else{
            navigate("../sex",{state:{
                id : location.state.id,
                password:passValue,
                name : location.state.name,
                birthday:location.state.birthday,
                phone:location.state.phone,
                cicode : location.state.cicode,
                dicode : location.state.dicode,
            },replace:true})
        }
    }
    return (
        <div className='join-password-check-page'>
            <JoinPager
                num={3}
            ></JoinPager>
            <p className='info'>비밀번호 재입력(필수 정보)</p>
            <p className='sub-info'>방금 입력하신 비밀번호를 다시 한번 확인하는 페이지입니다. 아래 칸에 비밀번호를 동일하게 입력 후 [다음] 버튼을 눌러주세요.</p>
            <div className='items item-first last-item'>
                <div className='input-colum-title'>
                    <p>비밀번호</p>
                </div>
                <input type='password' className='input-colum' onChange={(event)=>{
                    const data = event.target.value
                    setPassword(data)
                }} placeholder='비밀번호를 입력해주세요'></input>
                {!passEqualCheck && passValue?
                <div className='input-colum-comment'>
                    <div>
                        <p>직전에 입력한 비밀번호와 다릅니다.</p>
                    </div>
                </div>:<></>
                }
                
            </div>
            <button disabled = {passEqualCheck ? false:true} onClick={() => goToNext()}>다음</button>
            {modal? <Modal
                title={modal_title}
                contents={modal_contents}
                closeModal = {closeModalView}
                ></Modal>:<></>}
        </div>
    );
};

export default JoinPassCheck;