import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAuth } from '../Auth/AuthProvider';

export const ProtectRoute = ({children}) => {
    const navigate = useNavigate()
    const {user} = useAuth();
    if(user === undefined){
        return (
            <></>
        )
    }else if(!user){
        navigate(-1)
    }else{
        return children;
    }
};

export default ProtectRoute;