import axios from 'axios';
import React, { createContext, useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { QueryContext } from '../App';
const QueryGuardRouter = ({children}) => {
    const location = useLocation();
    const value = useContext(QueryContext);
    const param = new URLSearchParams(location.search)
    const queryContextParma = new URLSearchParams(value[0])
    useEffect(()=>{
        if(location.pathname === "/" || location.pathname === "/find-account/id" || location.pathname === "/join" || location.pathname === "/find-account/password" || location.pathname === "/login" || location.pathname === "/logout" || window.location.pathname.startsWith("/join/terms") || window.location.pathname.startsWith("/withdraw")){
        }else{
            if(!sessionStorage.getItem('redirectInfo')){
                if(queryContextParma.get('redirect')){
                    if(queryContextParma.get('redirect').startsWith("http://") || queryContextParma.get('redirect').startsWith("https://")){
                        window.location.href = queryContextParma.get('redirect')
                    }else{
                        window.location.href = window.location.protocol+"//" + queryContextParma.get('redirect')
                    }
                }else{
                    window.location.href = "/"
                }
            }
            else{
                if(sessionStorage.getItem('redirectInfo').startsWith("http://") || sessionStorage.getItem('redirectInfo').startsWith("https://")){
                    window.location.href = sessionStorage.getItem('redirectInfo')
                }else{
                    window.location.href = window.location.protocol+"//" + sessionStorage.getItem('redirectInfo')
                }
            }
        }
    },[])
    if(param.get('redirect')){
        if(location.pathname === "/"){
            window.location.href = "/login?redirect="+param.get('redirect')
        }
        return children
    }else if(queryContextParma.get('redirect')){
        return children
    }else if(sessionStorage.getItem('redirectInfo')){
        return children
    }
    else if(window.location.pathname.startsWith("/join/terms")){
        return children
    }
    else if(location.pathname === "/find-account/id" || location.pathname === "/join" || window.location.pathname.startsWith("/withdraw") || location.pathname === "/find-account/password"){
        return children
    }
    else{
        window.location.href = "/login?redirect=" + window.location.protocol + "//"+ process.env.REACT_APP_WEB_MEMBER_MAIN_URL
    }
};

export default QueryGuardRouter;