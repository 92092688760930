import React, { useEffect } from 'react';
import { useState } from 'react';
import checkboxDisable from '../../assets/svgs/CheckBoxDisable.svg'
import checkboxEnable from '../../assets/svgs/CheckBoxEnable.svg'
import CheckDeviceService from '../../Auth/CheckDeviceService';
import './Modal.css'
import style from './MvnoModal.module.css'
const MvnoModal = ({SelectData}) => {
    const [select,setSelect] = useState()
    const [thirdParty,setThirdParty] = useState(false)
    return (
        <div className={style["modal-wrap"]}>
            <div className={style["modal"]}>
                <div className={style['title']}><p>알뜰폰 통신사를 선택해주세요.</p></div>
                <div className={style["select-btn-box"]}>
                    <div className={style["select-btn-margin-box"]}>
                        <button onClick={()=>{setSelect(5)}} className={`${style["select-btn"]} ${select === 5? style["select"]:style["not-select"]}`}>알뜰폰 SKT</button>
                    </div>
                    <div className={style["select-btn-margin-box"]}>
                        <button onClick={()=>{setSelect(6)}} className={`${style["select-btn"]} ${select === 6? style["select"]:style["not-select"]}`}>알뜰폰 KT</button>
                    </div>
                    <div className={style["select-btn-margin-box"]}>
                        <button onClick={()=>{setSelect(7)}} className={`${style["select-btn"]} ${select === 7? style["select"]:style["not-select"]}`}>알뜰폰 LGU+</button>
                    </div>
                </div>
                <div className={style["checkBtn"]} >
                    <img onClick={()=>{setThirdParty(!thirdParty);}} src={thirdParty? checkboxEnable : checkboxDisable}></img>
                    <div>
                        <p onClick={()=>{setThirdParty(!thirdParty);}}>[필수] 개인정보 제 3자 제공 동의</p>
                        <a target='_blank' href="https://cert.vno.co.kr/app/agree/agree_m_05.jsp">
                            <p onClick={()=>{if(CheckDeviceService.checkMobile()){window.location.href = "https://cert.vno.co.kr/app/agree/agree_m_05.jsp"}}}>보기</p>
                        </a>  
                    </div>
                </div>
                <div><button disabled={!thirdParty} onClick={()=>{SelectData(select)}}>확인</button></div>
            </div>
        </div>
    );
};

export default MvnoModal;