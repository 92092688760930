import React, { useEffect, useState } from 'react';
import CheckBoxIcon from '../../Layout/CheckBoxIcon/CheckBoxIcon';
import style from './AgreeTerm.module.css'
const AgreeTerm = ({data,contents,onClickTerms}) => {
    const [value,setvalue] = useState(false)
    useEffect(()=>{
        setvalue(data)
    })
    return (
        <div onClick={()=>{
            setvalue(!value)
            onClickTerms(!value)}} className={style.root}>
            <CheckBoxIcon thema={value}></CheckBoxIcon>
            <p>{contents}</p>
        </div>
    );
};

export default AgreeTerm;