import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import BFFPrivateHttpRequestHelper from '../Helper/BFFPrivateHttpRequestHelper';
import { useAuth } from '../Auth/AuthProvider';
import AuthService from '../Auth/AuthService';
import MemberService from '../Auth/MemberService';

const PrivateGuard = ({children}) => {
    const [guard , setGuard] = useState(undefined)
    const {user} = useAuth()
    const location = useLocation()
    const [serviceEnable,setServiceEnable] = useState(undefined)
    const [rookieEnable,setRookieEnable] = useState(undefined)
    const [moreEnable,setMoreEnable] = useState(undefined)
    const queryParam = new URLSearchParams(location.search)
    const navigate = useNavigate()
    useEffect(()=>{
        getRookieData()
        getMoreData()
        getPrivateAuth()
        getUser()
    },[])
    const getPrivateAuth = async() => {
        if(!queryParam.get('cancel_url') || !queryParam.get('success_url')){
            navigate(-1)
        }
        try{
            const result = await BFFPrivateHttpRequestHelper.validatePrivateToken()
            setGuard(true)
        }catch(err){
            setGuard(false)
        }
    }
    const getUser = async() => {
        const result = await AuthService.Login()
    }
    const getRookieData = async() => {
        let rookieEnableCheck = false
        try{
            const result = await MemberService.getRookieServiceData()
            if(result.terms){
                result.terms.forEach((element) => {
                    if(element.terms_code === "001"){
                        rookieEnableCheck = true
                    }
                });
            }
            setRookieEnable(rookieEnableCheck)
        }catch(err){
            if(err.response?.status === 404){
                console.error(err)
                setRookieEnable(false)
            }else{
                navigate(-1)
            }
        }
    }
    const getMoreData = async() => {
        try{
            const result = await MemberService.getMoreServiceData()
            if(result.is_used){
                setMoreEnable(true)
            }else{
                setMoreEnable(false)
            }
        }catch(err){
            navigate(-1)
        }
    }
    useEffect(()=>{
        if(rookieEnable !== undefined && moreEnable !== undefined){
            if(!rookieEnable && !moreEnable){
                setServiceEnable(false)
            }else{
                setServiceEnable(true)
            }
        }
    },[rookieEnable,moreEnable])
    if(guard){
        if(serviceEnable){
            navigate('/withdraw/cancel-affiliate-service'+location.search,{replace:true})
        }else if(serviceEnable === undefined){
        }else{
            return children
        }
    }else if(guard === undefined){
        
    }else{
        navigate('/withdraw/input-password'+location.search,{replace:true})
    }

};

export default PrivateGuard;