import React from 'react';

const CheckBoxIcon = ({thema}) => {
    return (
        <>
        {thema? 
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="10" cy="10" r="10" fill="#3997FE"/>
        <path d="M4.5 10L8.5 14L15.5 7" stroke="white" strokeWidth="2"/>
        </svg>
        :
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="10" cy="10" r="10" fill="#E0E0E0"/>
        <path d="M4.5 10L8.5 14L15.5 7" stroke="white" strokeWidth="2"/>
        </svg>
        }
        </>
    );
};

export default CheckBoxIcon;