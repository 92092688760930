import React from 'react';
import './Modal.css'
const Modal = ({title,contents,closeModal}) => {
    return (
        <div className='modal_wrap'>
            <div className='modal'>
                <div className='title'>
                    <p>{title}</p>
                </div>
                <div className='contents'>
                    {
                        contents.split("\n").map((text)=>{
                            return(<p key={text}>{text}</p>);
                        })
                    }
                </div>
                <div>
                    <button onClick={closeModal}>확인</button>     
                </div>
            </div>
        </div>
    );
};

export default Modal;