import React, { createContext, useContext, useEffect, useState } from 'react';
import { Link, Navigate, useLocation, useNavigate } from 'react-router-dom';
import Slider from "react-slick";
import Img1 from "../../assets/images/slider01.png"
import Img2 from "../../assets/images/slider02.png"
import Img3 from "../../assets/images/slider03.png"
// Import css files
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./JoinUs.css"
import SmsModal from '../../Layout/Modals/SmsModal';
import { QueryContext } from '../../App';
const JoinSuccess = () => {
    const query = useContext(QueryContext);
    const navigate = useNavigate()
    const location = useLocation();
    const [device,setdevice] = useState(false)
    const [smsmodal,setsmsModal] = useState(false)
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1
    };
    useEffect(()=>{
        const user = navigator.userAgent
        if(user.indexOf('iPhone') > -1){
            setdevice(true)
        }else if(user.indexOf('Android') > -1){
            setdevice(true)
        }else{
            setdevice(false)    
        }
        window.onpopstate = function(event) {
            if(event){
                const redirectItem = sessionStorage.getItem('redirectInfo')
                if(redirectItem === "null" || redirectItem === null){
                    window.location.replace("http://www.superbin.co.kr")
                }else{
                    if(redirectItem.startsWith("http://") || redirectItem.startsWith("https://")){
                        window.location.replace(redirectItem)
                        window.nativeApi.execute(window.NativeApiMethod.CLOSE_WEB_VIEW, {
                            user_id: location.state.id,
                            user_pw: location.state.password
                          }, '');
                    }else{
                        window.location.replace("http://"+redirectItem)
                        window.nativeApi.execute(window.NativeApiMethod.CLOSE_WEB_VIEW, {
                            user_id: location.state.id,
                            user_pw: location.state.password
                          }, '');
                    }
                }
            }
          }
    },[])
    const closeModalView = () => {
        setsmsModal(false)
    }
    const appdown = () => {
        if(device){
            window.open("http://onelink.to/8ceuj4", '_blank');
        }else{
            setsmsModal(true)
        }
    }
    return (
        <div className='Join_success_page'>
            <div className='items'>
                <p className='info'>수퍼빈 회원 가입 완료</p>
                <p className='sub-info'>축하드립니다. 수퍼빈 회원 가입이 완료되었습니다.</p>
                <hr/>
                <div className='id-box'>
                    <p className='title'>아이디</p>
                    <p className='ID'>{location.state.id}</p>
                </div>
                <hr/>
                <Slider {...settings}>
                    <div className='slider-img'>
                    <img src={Img1}></img>
                    <div>
                        <p>수퍼빈 회원이 되면 포인트를</p>
                        <p>현금으로 바꿀수 있어요</p>
                    </div>
                    </div>
                    <div className='slider-img'>
                    <img src={Img2}></img>
                    <div>
                        <p>적립된 포인트 내역을</p>
                        <p>확인할 수 있어요</p>
                    </div>
                    </div>
                    <div className='slider-img'>
                    <img src={Img3}></img>
                    <div>
                        <p>네프론 상태를 실시간으로 확인할 수 있어요</p>
                        <p>(수퍼빈 앱에서만 확인 가능)</p>
                    </div>
                    </div>
                </Slider>
            </div>
            <button onClick={() =>{
                const redirectItem = sessionStorage.getItem('redirectInfo')
                if(redirectItem === "null" || redirectItem === null){
                    window.location.replace("http://www.superbin.co.kr")
                }else{
                    if(redirectItem.startsWith("http://") || redirectItem.startsWith("https://")){
                        window.location.replace(redirectItem)
                        window.nativeApi.execute(window.NativeApiMethod.CLOSE_WEB_VIEW, {
                            user_id: location.state.id,
                            user_pw: location.state.password
                          }, '');
                    }else{
                        window.location.replace("http://"+redirectItem)
                        window.nativeApi.execute(window.NativeApiMethod.CLOSE_WEB_VIEW, {
                            user_id: location.state.id,
                            user_pw: location.state.password
                          }, '');
                    }
                }
                }}>로그인하기</button>
            {smsmodal? <SmsModal
            closeModal = {closeModalView}>
            </SmsModal>:
            <></>
            }

        </div>
    );
};

export default JoinSuccess;