import React, { useEffect, useState } from 'react';
import style from './SmsNumTimer.module.css'

const SmsNumTimer = ({TimerStarted,TimerEnded}) => {
    const [minutes, setMinutes] = useState(3);
    const [seconds, setSeconds] = useState(0);
    useEffect(() => {
      const countdown = setInterval(() => {
        if (seconds > 0) {
          setSeconds(seconds - 1);
        }
        if (seconds === 0) {
          if (minutes === 0) {
            clearInterval(countdown);
          } else {
            setMinutes(minutes - 1);
            setSeconds(59);
          }
        }
        if(seconds == 0 && minutes == 0){
            TimerEnded()
        }
      }, 1000);
      return () => clearInterval(countdown);
    }, [minutes, seconds]);
    return (
    <div className={style['root']}>
        <div className={style['timer']}>
            {minutes || seconds ?
            <div>남은 시간 {minutes}:{seconds < 10 ? `0${seconds}` : seconds}</div>:
            <div>인증시간이 만료되었습니다.</div>
            }
        </div>
        <div className={style['reload-timer']} onClick={()=>{setMinutes(3); setSeconds(0); TimerStarted()}}>재전송</div>
    </div>
    );
};

export default SmsNumTimer;