import React, { useEffect, useState } from 'react';
import CheckDeviceService from '../../../CheckDeviceService';
import {CheckBox} from '../../../../assets/svgs/icons';
import style from './RecertificationConsent.module.css';
const RecertificationConsent = () => {
    const validateList = ['privacy','service','uniqueIdentification','telecom']
    const [allcheck,setAllCheck] = useState(false)
    const [validate,setValidate] = useState({
        privacy : false,
        service : false,
        uniqueIdentification : false,
        telecom: false
    })
    useEffect(()=>{
        let check = true
        for(const validateKey of validateList){
            if(!validate[validateKey]) {
                check = false
            }
        }
        setAllCheck(check)
    },[validate])
    const checkAll = (state) => {
        setValidate({
            privacy : state,
            service : state,
            uniqueIdentification : state,
            telecom: state
        })
    }
    return (
        <> 
        <div className={style['agree']}>
            <div>
                <p className={style['info']}>수퍼빈 회원 인증</p>
                <p className={style['sub-info']}>수퍼빈 회원 인증(본인 인증)을 시작합니다. 본인인증기관 약관에 동의해주세요.</p>
                <div className={style['checkAllBtn']} onClick={()=>checkAll(!allcheck)}>
                    <CheckBox status={allcheck} onSetclick={(value)=>{}}></CheckBox>
                        <div>
                            <p>전체동의</p>
                        </div>
                </div>
                <div className={style['allcheck-line']}></div>
                <div className={`${style['checkBtn']} ${style['checkBtn-first']}`}>
                    <CheckBox status={validate.privacy} onSetclick={(value)=>{
                        const data = {...validate}
                        data.privacy = value
                        setValidate(data)
                        }}></CheckBox>
                    <div>
                        <p onClick={()=>{
                            const data = {...validate}
                            data.privacy = !data.privacy
                            setValidate(data)
                        }}>[필수] 개인정보 이용 동의</p>
                        <a target='_blank' href="https://cert.vno.co.kr/app/agree/agree_m_01.jsp">
                            <p onClick={()=>{if(CheckDeviceService.checkMobile()){window.location.href = "https://cert.vno.co.kr/app/agree/agree_m_01.jsp"}}}>
                            보기</p>
                        </a>
                    </div>
                </div>
                <div className={style['checkBtn']}>
                    <CheckBox status={validate.uniqueIdentification} onSetclick={(value)=>{
                        const data = {...validate}
                        data.uniqueIdentification = value
                        setValidate(data)
                        }}></CheckBox>
                    <div>
                        <p onClick={()=>{
                            const data = {...validate}
                            data.uniqueIdentification = !data.uniqueIdentification
                            setValidate(data)
                        }}>[필수] 고유식별 정보처리 동의</p>
                        <a target='_blank' href="https://cert.vno.co.kr/app/agree/agree_m_02.jsp">
                            <p onClick={()=>{if(CheckDeviceService.checkMobile()){window.location.href = "https://cert.vno.co.kr/app/agree/agree_m_02.jsp"}}}>보기</p>
                        </a>
                    </div>
                </div>
                <div className={style['checkBtn']}>
                    <CheckBox status={validate.service} onSetclick={(value)=>{
                        const data = {...validate}
                        data.service = value
                        setValidate(data)
                        }}></CheckBox>                   
                    <div>
                        <p onClick={()=>{
                            const data = {...validate}
                            data.service = !data.service
                            setValidate(data)
                        }}>[필수] 서비스 이용약관 동의</p>
                        <a target='_blank' href="https://cert.vno.co.kr/app/agree/agree_m_04.jsp">
                            <p onClick={()=>{if(CheckDeviceService.checkMobile()){window.location.href = "https://cert.vno.co.kr/app/agree/agree_m_04.jsp"}}}>보기</p>
                        </a>
                    </div>
                </div>
                <div className={`${style['checkBtn']} ${style['checkBtn-last']}`}>
                    <CheckBox status={validate.telecom} onSetclick={(value)=>{
                        const data = {...validate}
                        data.telecom = value
                        setValidate(data)
                        }}></CheckBox>                      
                    <div>
                        <p onClick={()=>{
                            const data = {...validate}
                            data.telecom = !data.telecom
                            setValidate(data)
                        }}>[필수] 통신사 이용약관 동의</p>
                        <a target='_blank' href="https://cert.vno.co.kr/app/agree/agree_m_03.jsp">
                            <p onClick={()=>{if(CheckDeviceService.checkMobile()){window.location.href = "https://cert.vno.co.kr/app/agree/agree_m_03.jsp"}}}>보기</p>
                        </a>
                    </div>
                </div>
            </div>
            <button onClick={()=>{}} disabled={allcheck ? false:true}>동의하고 인증정보 입력하기</button>
        </div>
        </>
    );
};

export default RecertificationConsent;