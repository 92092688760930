import React, { useContext, useEffect, useState } from 'react';
import style from './InputPassword.module.css'
import Input from '../../modules/Input/Input';
import BFFHttpRequestHelper from '../../Helper/BFFHttpRequestHelper';
import AlertContext from '../../modules/Modal/AlertModal/AlertContext';
import Cryptoservice from '../Cryptoservice';
import { useLocation, useNavigate } from 'react-router-dom';
import BFFPrivateHttpRequestHelper from '../../Helper/BFFPrivateHttpRequestHelper';
import { useAuth } from '../AuthProvider';
import MemberService from '../MemberService';

const InputPassword = () => {
    const {user} = useAuth()
    const location = useLocation()
    const [password,setPassword] = useState("")
    const [nextBtn,setNextBtn] = useState(true)
    const {alert:alertComp} = useContext(AlertContext)
    const [serviceEnable,setServiceEnable] = useState(false)
    const navigate = useNavigate()
    useEffect(()=>{
        if(password){setNextBtn(false)}
        else{setNextBtn(true)}
    },[password])
    useEffect(()=>{
        getRookieData()
        getMoreData()
    },[])
    const getRookieData = async() => {
        try{
            const result = await MemberService.getRookieServiceData()
            if(result.terms){
                result.terms.forEach((element) => {
                    if(element.terms_code === "001"){
                        setServiceEnable(true)
                    }
                });
            }
        }catch(err){
            if(err.response?.status === 404){
                console.error(err)
            }else{
                navigate(-1)
            }
        }
    }
    const getMoreData = async() => {
        try{
            const result = await MemberService.getMoreServiceData()
            if(result.is_used){
                setServiceEnable(true)
            }
        }catch(err){
            navigate(-1)
        }
    }
    const onClickNext = async() => {
        try{
            //비밀번호 확인!
            const body = {
                password: Cryptoservice.encrypt(password)
            }
            const result = await BFFPrivateHttpRequestHelper.getPrivateToken(body)
            try{
                sessionStorage.setItem('privacy_token',result.access_token)
                serviceEnable ? navigate("/withdraw/cancel-affiliate-service"+location.search,{replace:true}) : navigate("/withdraw"+location.search,{replace:true})
            }catch(err){
                navigate(-1)
            }
        }catch(error){
            //비밀번호 틀림
            if(error.response.status == 403){
                await alertComp("잘못된 비밀번호입니다","정확한 비밀번호를 다시 입력해주세요.",undefined,'확인')
            }else{
                await alertComp('오류 발생', '이용에 불편을 드려 죄송합니다. 서버 오류가 발생하였습니다. 계속해서 문제가 발생하는 경우 수퍼빈 카카오톡 채널로 문의 바랍니다.',undefined,'메인으로 돌아가기')
                navigate(-1)
            }
        }
    }

    return (
        <div className={style["page-start"]}>
            <div className={style["root"]}>
                <div className={style.title}>탈퇴 위한 로그인 비밀번호 입력</div>
                <div className={style["sub-title"]}>수퍼빈 서비스를 탈퇴 하기 위해 아래 칸에 사용 중인 로그인 시 입력하는 계정 비밀번호를 입력해주세요.</div>
                <div className={style["container-box"]}>
                    <Input type='password' title={'비밀번호'} value={password} holder={'비밀번호를 입력해주세요.'}
                            InputData={function (value) {
                                setPassword(value);
                            } } ></Input>
                    <div className={style["input-info"]}>
                        <div>   
                            <p className={style["input-info-title"]}>※ 비밀번호를 분실하셨나요?</p>
                            <p className={style["input-info-contents"]}>로그아웃 후 비밀번호 변경을 눌러 비밀번호를 변경해주세요.</p>
                        </div>
                    </div>
                </div>
                <div className={style["bottom-container-box"]}>
                    <button disabled={nextBtn} onClick={()=>{onClickNext()}}>확인</button>
                </div>
            </div>
        </div>
    );
};

export default InputPassword;