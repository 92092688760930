import React from 'react';
import style from '../WithDrawModal.module.css'
const SingleModal = ({code,title,btnTitle,onOkClick}) => {
    return (
        <>
        <div className={style["modal-background"]}></div>
        <div className={style["modal-wrap"]}>
              <div className={style["modal"]}>
                <p className={style["title"]}>{title}</p>
                <button onClick={()=>{onOkClick(code)}}>{btnTitle}</button>
              </div>
        </div>
        </>
    );
};

export default SingleModal;