import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

const IosBack = () => {
    const [device,setdevice] = useState(false)
    const navigate = useNavigate()
    const user = navigator.userAgent
    useEffect(()=>{
        if(user.indexOf('iPhone') > -1){
            setdevice(true)
        }else{
            setdevice(false)    
        }
    },[])

    return (
        <>
        {device? 
        <div className='ios-layout'>
            <div onClick={() => {navigate(-1)}}>&lt;</div>
        </div>:<></>}
        </>
    );
};

export default IosBack;