import axios from 'axios';
import React from 'react';
import { useLocation } from 'react-router-dom';
import { Navigate } from 'react-router';
import Login from '../Auth/Login/Login';

const AuthPrivateRouter = ({children}) => {
    const location = useLocation();
    const authcode = axios.defaults.headers.common.Authorization
    if(authcode){
        return children
    }else{
        return <Navigate replace to="/login"/>
    }
};

export default AuthPrivateRouter;