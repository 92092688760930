import React from 'react';
import style from './SmsNumInput.module.css'
import SmsNumTimer from './SmsNumTimer';
const SmsNumInput = ({title,holder,value,InputData,TimerStarted,TimerEnded}) => {
    return (
        <div className={style["input-box"]}>
            <p className={style.title}>{title}</p>
            <input type={'number'}
             placeholder={holder} 
             value={value}
             onChange={(e)=>{
                const value = e.currentTarget.value
                InputData(value)
                }} className={style.title}></input>
            <SmsNumTimer TimerStarted={function () {
                TimerStarted()
            } } TimerEnded={function () {
                TimerEnded()
            } }></SmsNumTimer>
        </div>
    );
};

export default SmsNumInput;