
import React, { useContext, useState } from 'react';
import AlertContext from '../Modal/AlertModal/AlertContext';


const VerificateCodeComponentViewModel =  {
    VerificateCodeHook : () => {
        const {alert : alertComp} = useContext(AlertContext)
        const [smsValidateNum,setSmsValidateNum] = useState("")
        const [timerStatus,setTimerStatus] = useState(true)
        return{smsValidateNum,timerStatus,setSmsValidateNum,setTimerStatus}
    }
};

export default VerificateCodeComponentViewModel;