import React from 'react';
import style from './Input.module.css'
const Input = ({title, type , holder, value ,InputData}) => {
    return (
        <div className={style["input-box"]}>
            <p className={style.title}>{title}</p>
            <input type={type} placeholder={holder} value={value} onChange={(e)=>{
                const value = e.target.value
                InputData(value)
                }} className={style.title}></input>
            <hr></hr>
        </div>
    );
};

export default Input;