import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { QueryContext } from '../../App';
import Modal from '../../Layout/Modals/Modal';
import Timer from '../../modules/Timer/Timer';
import AuthService from '../AuthService';
import JoinUsService from '../JoinUs/JoinUsService';
import './SmsCheck.css'
import ConfirmContext from '../../modules/Modal/ConfirmModal/ConfirmContext';
const SmsCheck = () => {
    const location = useLocation();
    const query = useContext(QueryContext);
    const {confirm: confirmComp } = useContext(ConfirmContext);

    const navigate = useNavigate()
    const [certificationNumberValue,setcertificationNumberValue] = useState('')
    //Guardion 인증 제목 변경에 따름
    const [title , setTitle] = useState("인증번호 입력")
    //알림 모달
    const [modal,setModal] = useState(false)
    const [modal_title,setmodal_title] = useState('')
    const [modal_contents,setmodal_contents] = useState('')
    const [modal_status,setmodal_status] = useState('')
    // 인증 관련
    const [restarter,setRestarter] = useState(false)
    const [endtimer,setendTimer] = useState(false)
    const [requestSeqCode,setRequestSeqCode] = useState('')
    const [responseSeqCode,setResponseSeqCode] = useState('')
    useEffect(()=>{
        if(!checkNotNormalEnter()){
            navigate("/"+query[0],{replace:true})
        }else{
            if(location.state.child_name){
                setTitle("보호자 인증번호 입력")
            }
        }
        if(location.state.request_seq_code){
            setRequestSeqCode(location.state.request_seq_code)
            setResponseSeqCode(location.state.response_seq_code)
        }
    },[])
    const checkNotNormalEnter =( ) => {
        //이전 페이지 정보가 없다.
        if(!location?.state?.last){
            return false
        }else{
            return true
        }
    }
    const closeModalView = () => {
        if(modal_status == 1){
            if(!sessionStorage.getItem('byLogin')){
                const redirectItem = sessionStorage.getItem('redirectInfo')
                if(redirectItem.startsWith("http://") || redirectItem.startsWith("https://")){
                    window.location.replace(redirectItem)
                    window.nativeApi.execute(window.NativeApiMethod.CLOSE_WEB_VIEW, {}, '');
                }else{
                    window.location.replace("http://"+redirectItem)
                    window.nativeApi.execute(window.NativeApiMethod.CLOSE_WEB_VIEW, {}, '');
                }
            }else{
                navigate("/login"+query[0],{replace:true})
            }
        }else{
            setModal(false)
        }
    }
    const closeModalViewByRegistPhone = () => {
        if(!sessionStorage.getItem('byLogin')){
            const redirectItem = sessionStorage.getItem('redirectInfo')
            if(redirectItem.startsWith("http://") || redirectItem.startsWith("https://")){
                window.location.replace(redirectItem)
                window.nativeApi.execute(window.NativeApiMethod.CLOSE_WEB_VIEW, {}, '');
            }else{
                window.location.replace("http://"+redirectItem)
                window.nativeApi.execute(window.NativeApiMethod.CLOSE_WEB_VIEW, {}, '');
            }
        }else{
            navigate("/login"+query[0],{replace:true})
        }
    }
    const checkValueIn = () =>{
        if(!certificationNumberValue){
            setmodal_title('알림')
            setmodal_contents('인증 번호를 입력해주세요')
            setModal(true)
            return false
        }else{
            return true
        }
    }
    //핸드폰 인증 번호 재조회
    const certificationPhone = async () => {
        setRestarter(true);
        if(location.state.last ===  "NiceValidation" || location.state.last ===  "GuardianNiceValidation"){
            const body = {
                phone : location.state.phone,
                name : location.state.name,
                rr_number : location.state.rr_number,
                telco : location.state.telco
            }
            //사용자에게 문자 보내는 로직
        try{
            const smsSendResult = await JoinUsService.postNiceIdSMS(body)
            setRequestSeqCode(smsSendResult.request_seq_code)
            setResponseSeqCode(smsSendResult.response_seq_code)
        }catch(error){
            setmodal_title('알림')
            setmodal_contents('네트워크 에러입니다. 다시 시도해주세요')
            setModal(true)
        }
        }
    }
    //핸드폰 인증으로 받은 번호로 인증을 진행
    const certificationByInputNumber = async() => {
        if(checkValueIn()){
            if(!endtimer){
                try{
                    if(location.state.last ===  "NiceValidation"){
                        const data = {
                            name:location.state.name,
                            auth_code:certificationNumberValue,
                            phone: location.state.phone,
                            request_seq_code:requestSeqCode,
                            response_seq_code:responseSeqCode
                        }
                        const result = await JoinUsService.postNiceIdSMSValidation(data)
                        if(result.result_code === "RegisteredPhone"){
                            const confirm = await confirmComp('알림','이미 가입되어 있는 휴대전화 번호입니다. 아이디를 분실한 경우라면 [종료] 버튼을 눌러 아이디 찾기를, 새롭게 회원가입을 하고 싶은 경우 [계속] 버튼을 눌러주세요.',undefined,'종료','계속')
                            if(confirm){closeModalViewByRegistPhone()}
                            else{certificationOver(result.temporary_token , result.ci , result.di)}
                        }else{
                            certificationOver(result.temporary_token , result.ci , result.di)
                        }
                    }else if(location.state.last ===  "NiceGuardValidation"){
                        const data = {
                            name:location.state.name,
                            auth_code:certificationNumberValue,
                            phone: location.state.phone,
                            request_seq_code:requestSeqCode,
                            response_seq_code:responseSeqCode
                        }
                        const result = await JoinUsService.postGuardianNiceIdSMSValidation(data)
                        certificationOver(result.temporary_token  , result.ci , result.di)
                    }
                }catch(error){
                    console.error(error)
                    if(error.response.status === 409){
                        if(error.response.data.message === "RegisteredCi"){
                            setmodal_title('중복가입불가')
                            setmodal_status(1)
                            setmodal_contents('이미 다른 휴대전화 번호로 가입된 회원입니다. 휴대전화 번호를 변경 혹은 추가하고 싶으신 경우 기존 계정으로 로그인하여 설정해주세요')
                            setModal(true)
                        }else if(error.response.data.message === "RegisteredCiAndPhone"){
                            setmodal_title('알림')
                            setmodal_status(1)
                            setmodal_contents('이미 가입되어있는 정보입니다. 계정을 분실하셨다면 아이디 찾기를 눌러주세요.')
                            setModal(true)
                        }else if(error.response.data.message === "RegisteredPhone"){
                            setmodal_title('알림')
                            setmodal_status(1)
                            setmodal_contents('이미 가입되어있는 정보입니다. 계정을 분실하셨다면 아이디 찾기를 눌러주세요.')
                            setModal(true)
                        }else if(error.response.data.message === "RegisteredUser"){
                            setmodal_title('알림')
                            setmodal_status(1)
                            setmodal_contents('이미 가입되어있는 정보입니다. 계정을 분실하셨다면 아이디 찾기를 눌러주세요.')
                            setModal(true)
                        }else{
                            setmodal_status(0)
                            setmodal_title('알림')
                            setmodal_contents('인증번호가 잘못되었습니다. 다시 입력해주세요.')
                            setModal(true)
                        }
                    }else{
                        setmodal_status(0)
                        setmodal_title('알림')
                        setmodal_contents('인증번호가 잘못되었습니다. 다시 확인해주세요.')
                        setModal(true)
                    }
                }
            }else{
                setmodal_status(0)
                setmodal_title('알림')
                setmodal_contents('입력 시간이 초과되었습니다. 재전송 버튼을 눌러 인증 문자를 다시 받아주세요.')
                setModal(true)
            }
        }
    }
    //인증 끝
    const certificationOver = async(temporary_token , cicode , dicode) => {
        if(temporary_token){axios.defaults.headers.common['Authorization'] = "Bearer " + temporary_token}
        if(location.state.last ===  "NiceValidation"){
            if(location.state.guard){
                navigate("../guard",{state:{
                    name:location.state.name,
                    phone: location.state.phone,
                    birthday : location.state.birthday,
                    cicode : cicode,
                    dicode : dicode
                },replace:true})
            }else{
                navigate("../id",{state:{
                    name:location.state.name,
                    phone: location.state.phone,
                    birthday : location.state.birthday,
                    cicode : cicode,
                    dicode : dicode
                },replace:true})
            }
        //보호자 인증 회원가입이다.
        }else if(location.state.last ===  "NiceGuardValidation"){
            navigate("../id",{state:{
                name:location.state.child_name,
                phone: location.state.child_phone,
                birthday : location.state.child_birth_at,
                cicode : location.state.cicode,
                dicode : location.state.dicode,
                guardion_name:location.state.name,
                guardion_birthday : location.state.birthday,
                guardion_cicode : cicode,
                guardion_dicode : dicode
            },replace:true})
        }
    }
    return (
        <div className='sms_page'>
            <p className='info'>{title}</p>
            <p className='sub-info'>문자메시지로 수신 받은 인증번호 6자리를 아래 칸에 입력 후 [인증하기] 버튼을 눌러주세요.</p>
            <div className='items'>
                <div className='input-colum-title'>
                    <p>인증번호</p>
                </div>
                <input type='text' className='input-colum' onChange={(event)=>{
                    const data = event.target.value
                    setcertificationNumberValue(data)
                }} placeholder='인증번호를 입력해주세요'></input>
                <div className='input-colum-comment'>
                <Timer
                    endtimer = {()=>{}}
                    setStarted={()=>{setRestarter(false);}}
                    restart={restarter}
                ></Timer>
                    <div>
                        <p className='sms-resend' 
                        onClick={async () => {
                            certificationPhone()
                            }}>재전송</p>
                        <hr></hr>
                    </div>
                </div>
            </div>
            <div className='items last-item'>
                <div className='comments'>
                    {location.state.last ===  "NiceValidation" || location.state.last ===  "NiceGuardValidation"?
                    <ol>
                        <li className='title'>※ 문자메시지를 받지 못한 경우</li>
                        <li className='sub-title'>휴대전화의 차단메시지함을 확인하시거나 가입한 통신사로 문자 차단 서비스를 이용하고 있는지 확인해주세요.</li>
                    </ol>:
                    <ol>
                        <li className='title'>※ 문자메시지를 받지 못했다면?</li>
                        <li className='sub-title'>문자메시지가 차단된 경우(1600-6217 발송됨) :</li>
                        <li className='content'>휴대전화의 차단메시지함을 확인하시거나 가입한 통신사로 문자 차단 서비스를 이용하고 있는지 확인해주세요.</li>
                    </ol>
                } 
                </div>
            </div>
            <button onClick={() => certificationByInputNumber()}>인증하기</button>

            {modal? <Modal
                title={modal_title}
                contents={modal_contents}
                closeModal = {closeModalView}
                ></Modal>:<></>}
        </div>
    );
};

export default SmsCheck;