import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import backBtn from '../../assets/icons/arrow_back.png'
import './hnb.css'
const Hnb = ({}) => {
    const location = useLocation()
    const [device,setdevice] = useState(false)
    const [isloginPage,setisLoginPage] = useState(false)
    const navigate = useNavigate()
    const user = navigator.userAgent
    useEffect(()=>{
        if(user.indexOf('iPhone') > -1){
            setdevice(true)
        }else{
            setdevice(false)    
        }
        if(location.pathname.startsWith("/login")){
            setisLoginPage(true)
        }else{
            setisLoginPage(false)
        }
    })
    return (
        <div className={!device? 'hnb':'hnb hnb-ios'}>
            {device? <img src={backBtn} onClick={() => {
                if(window.history.length > 1){navigate(-1);}else{window.nativeApi.execute(window.NativeApiMethod.CLOSE_WEB_VIEW, {}, '');}
            }}></img>:<></>}
            {isloginPage?
            <svg className='login-logo' width="166" height="47" viewBox="0 0 166 47" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M46.5257 30.9592C46.5257 34.6821 43.2837 36.4773 39.5917 36.4773C36.1424 36.4773 33.5884 35.0305 32.3489 32.3707L36.2129 30.1961C36.6937 31.6121 37.867 32.4369 39.5917 32.4369C41.0076 32.4369 41.9692 31.9561 41.9692 30.9548C41.9692 28.4362 33.0723 29.8168 33.0723 23.7473C33.0723 20.2274 36.0718 18.2645 39.627 18.2645C42.4191 18.2645 44.8363 19.5393 46.1817 21.9212L42.3883 23.9899C41.8722 22.8872 40.9062 22.23 39.627 22.23C38.5243 22.23 37.6244 22.7108 37.6244 23.6459C37.6244 26.1998 46.5213 24.6119 46.5213 30.9592H46.5257Z" fill="#1370E0"/>
            <path d="M64.7033 18.7496V35.9964H60.2527V34.0645C59.2161 35.6171 57.4253 36.4772 55.1139 36.4772C51.4572 36.4772 48.5945 33.9233 48.5945 29.3359V18.7452H53.0452V28.7801C53.0452 31.1268 54.4611 32.3309 56.4593 32.3309C58.6339 32.3309 60.2527 31.0562 60.2527 28.0523V18.7408H64.7033V18.7496Z" fill="#1370E0"/>
            <path d="M86.2464 27.3687C86.2464 32.5075 82.453 36.4729 77.8303 36.4729C75.3117 36.4729 73.4855 35.5422 72.2417 33.9543V42.8864H67.791V18.7497H72.2417V20.7832C73.4811 19.1952 75.3117 18.2645 77.8303 18.2645C82.453 18.2645 86.2464 22.23 86.2464 27.3687ZM81.7958 27.3687C81.7958 24.4707 79.7623 22.5034 77.0011 22.5034C74.2398 22.5034 72.2417 24.4707 72.2417 27.3687C72.2417 30.2667 74.2751 32.234 77.0011 32.234C79.727 32.234 81.7958 30.2667 81.7958 27.3687Z" fill="#1370E0"/>
            <path d="M97.5251 32.4413C99.2498 32.4413 100.63 31.7179 101.389 30.7166L104.975 32.7854C103.356 35.132 100.767 36.4773 97.4589 36.4773C91.663 36.4773 88.0063 32.5119 88.0063 27.3731C88.0063 22.2344 91.6983 18.269 97.1105 18.269C102.214 18.269 105.835 22.305 105.835 27.3731C105.835 28.0304 105.765 28.617 105.663 29.1993H92.6598C93.2818 31.4753 95.1785 32.4413 97.5207 32.4413H97.5251ZM101.389 25.7499C100.838 23.2666 98.9763 22.2653 97.1105 22.2653C94.7286 22.2653 93.1098 23.54 92.5937 25.7499H101.389Z" fill="#1370E0"/>
            <path d="M117.864 18.4012V23.3679C115.553 23.09 112.62 24.1266 112.62 27.748V35.992H108.169V18.7496H112.62V21.7138C113.515 19.3672 115.69 18.4012 117.864 18.4012Z" fill="#1370E0"/>
            <path d="M138.675 27.3686C138.675 32.5074 134.882 36.4728 130.259 36.4728C127.74 36.4728 125.914 35.5421 124.67 33.9542V35.9876H120.22V11.8509H124.67V20.7831C125.91 19.1952 127.74 18.2644 130.259 18.2644C134.882 18.2644 138.675 22.2299 138.675 27.3686ZM134.224 27.3686C134.224 24.4706 132.191 22.5034 129.43 22.5034C126.668 22.5034 124.67 24.4706 124.67 27.3686C124.67 30.2666 126.704 32.2339 129.43 32.2339C132.156 32.2339 134.224 30.2666 134.224 27.3686Z" fill="#1370E0"/>
            <path d="M140.373 13.9551C140.373 12.473 141.617 11.1938 143.099 11.1938C144.581 11.1938 145.86 12.4686 145.86 13.9551C145.86 15.4416 144.617 16.681 143.099 16.681C141.582 16.681 140.373 15.4371 140.373 13.9551ZM140.889 18.7498H145.34V35.9966H140.889V18.7498Z" fill="#1370E0"/>
            <path d="M164.814 25.4059V35.9966H160.364V25.9616C160.364 23.615 158.948 22.4108 156.949 22.4108C154.775 22.4108 153.156 23.6856 153.156 26.6895V36.001H148.705V18.7497H153.156V20.6817C154.193 19.1291 155.983 18.269 158.295 18.269C161.951 18.269 164.814 20.8229 164.814 25.4103V25.4059Z" fill="#1370E0"/>
            <path d="M6.71265 15.6091H1.18573V40.0855H6.71265V15.6091Z" fill="url(#paint0_linear_6437_22242)"/>
            <path d="M19.4029 26.9938C20.8409 25.2691 21.6966 23.0416 21.6658 20.62C21.5996 15.243 17.1048 10.9688 11.7279 10.9688H5.83046C3.26329 10.9688 1.18573 13.0464 1.18573 15.6091L6.71265 17.2544C6.71265 16.7648 7.10963 16.3678 7.59925 16.3678H11.6529C13.6555 16.3678 15.4728 17.669 15.9933 19.6054C16.6726 22.1373 14.9699 24.656 12.6101 25.0574C12.2175 25.1235 11.8955 25.5249 11.8955 25.9263V29.5124C11.8955 30.0021 12.2925 30.399 12.7821 30.399H13.3291C16.2359 30.399 18.534 33.1868 17.9606 36.1642C17.5195 38.4711 15.4287 40.0899 13.0776 40.0899H1.18573L5.94073 44.7346C6.43476 45.2198 7.10081 45.4889 7.79333 45.4889H13.1482C18.8516 45.4889 23.6375 40.8221 23.5669 35.1188C23.5272 31.7929 21.8951 28.842 19.3985 26.9938H19.4029Z" fill="#1370E0"/>
            <path d="M13.9554 7.01212L7.34777 8.48097C6.77875 8.60889 6.23621 8.1722 6.23621 7.58996V4.16706C6.23621 3.63334 6.60673 3.16578 7.13163 3.05109L13.7392 1.58666C14.3082 1.45874 14.8508 1.89542 14.8508 2.47767V5.89615C14.8508 6.42988 14.4803 6.89744 13.9554 7.01212Z" fill="#1370E0"/>
            <defs>
            <linearGradient id="paint0_linear_6437_22242" x1="3.95139" y1="39.9576" x2="3.95139" y2="16.1252" gradientUnits="userSpaceOnUse">
            <stop stopColor="#1370E0" stopOpacity="0"/>
            <stop offset="0.24" stopColor="#1370E0"/>
            </linearGradient>
            </defs>
            </svg>:<></>
            }
            
            {device? <div></div>:<></>}
        </div>
    );
};

export default Hnb;