import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import JoinPager from '../../../Layout/JoinPager/JoinPager';

const Guard = () => {
    const location = useLocation()
    const navigate = useNavigate()
    const nextPage = () =>{
        navigate("../guardterms",{state:{
            name:location.state.name,
            phone: location.state.phone,
            birthday : location.state.birthday,
            cicode : location.state.cicode,
            dicode : location.state.dicode
        }})
    }
    return (
        <div className='join-password-page'>
        <JoinPager
            num={2}
        ></JoinPager>
        <p className='info'>만 14세 미만 보호자 인증</p>
        <p className='sub-info'>가입자가 만 14세 미만인 경우 보호자의 본인 인증이 필수입니다. 아래 [보호자 인증하기] 버튼을 클릭하여 보호자님 정보로 인증해주세요.</p>
        <button onClick={() => nextPage()}>보호자 인증하기</button>
    </div>
    );
};

export default Guard;