// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".SmsNumInput_input-box__RxI6w{width: 100%;}\n.SmsNumInput_input-box__RxI6w > p{font-weight: 400; font-size: 14px; line-height: 17px; color: #9E9E9E; }\n.SmsNumInput_input-box__RxI6w > input{width: 100%; font-weight: 600; font-size: 18px; padding: 10px 0px; color: #222222; border: none; border-bottom: 2px solid #BDBDBD; line-height:22px; outline: none; font-family: Pretendard;}\n.SmsNumInput_input-box__RxI6w > input:disabled{background-color: white; border: none; border-bottom: 2px solid #2681F3;}\n.SmsNumInput_input-box__RxI6w > input::placeholder{color: #BDBDBD;}", "",{"version":3,"sources":["webpack://./src/modules/SmsNumInput/SmsNumInput.module.css"],"names":[],"mappings":"AAAA,8BAAW,WAAW,CAAC;AACvB,kCAAe,gBAAgB,EAAE,eAAe,EAAE,iBAAiB,EAAE,cAAc,EAAE;AACrF,sCAAmB,WAAW,EAAE,gBAAgB,EAAE,eAAe,EAAE,iBAAiB,EAAE,cAAc,EAAE,YAAY,EAAE,gCAAgC,EAAE,gBAAgB,EAAE,aAAa,EAAE,uBAAuB,CAAC;AAC/M,+CAA4B,uBAAuB,EAAE,YAAY,EAAE,gCAAgC,CAAC;AACpG,mDAAgC,cAAc,CAAC","sourcesContent":[".input-box{width: 100%;}\n.input-box > p{font-weight: 400; font-size: 14px; line-height: 17px; color: #9E9E9E; }\n.input-box > input{width: 100%; font-weight: 600; font-size: 18px; padding: 10px 0px; color: #222222; border: none; border-bottom: 2px solid #BDBDBD; line-height:22px; outline: none; font-family: Pretendard;}\n.input-box > input:disabled{background-color: white; border: none; border-bottom: 2px solid #2681F3;}\n.input-box > input::placeholder{color: #BDBDBD;}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"input-box": "SmsNumInput_input-box__RxI6w"
};
export default ___CSS_LOADER_EXPORT___;
