import React from 'react';
import style from './VerificateCodeComponent.module.css'
import VerificateCodeComponentViewModel from './VerificateCodeComponentViewModel';
import SmsNumInput from '../SmsNumInput/SmsNumInput';
import FullWidthBtn from '../Button/FullWidthBtn/FullWidthBtn';
const VerificateCodeComponent = ({setValidateion,resendCode}) => {
    const {smsValidateNum,timerStatus,setSmsValidateNum,setTimerStatus} = VerificateCodeComponentViewModel.VerificateCodeHook()
    return (
        <div className={style['page-start']}>
            <div className={style['root']}>
                <div>
                    <p className={style['title']}>인증번호 입력</p>
                    <p className={style['description']}>문자메시지로 수신 받은 인증번호 6자리를 아래 칸에 입력 후 [인증하기] 버튼을 눌러주세요.</p>
                    <div className={style['item']}>
                        <SmsNumInput title={'인증번호'} holder={'인증번호를 입력해주세요'} value={smsValidateNum} InputData={function (value) {
                            setSmsValidateNum(value);
                        } } TimerStarted={function () {
                            setTimerStatus(true)
                            resendCode()
                        } } TimerEnded={function () {
                            setTimerStatus(false)
                        } }></SmsNumInput>
                    </div>
                    <div className={style['sub-description']}>
                        <p className={style['title']}>※ 문자메시지를 받지 못한 경우</p>
                        <p className={style['body']}>휴대전화의 차단메시지함을 확인하시거나 가입한 통신사로 문자 차단 서비스를 이용하고 있는지 확인해주세요.</p>
                    </div>
                    <div className={style['validate-end']}>
                        <FullWidthBtn onClick={function () {
                            setValidateion(smsValidateNum)
                        }}title={'인증하기'} 
                        disabled={smsValidateNum && timerStatus ? false:true}></FullWidthBtn>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default VerificateCodeComponent;