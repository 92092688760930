import axios from 'axios';
import moment from 'moment/moment';
import React from 'react';
import { Cookies } from 'react-cookie';
import HttpRequestHelper from '../Helper/HttpRequestHelper';
import BFFHttpRequestHelper from '../Helper/BFFHttpRequestHelper';
import AuthHttpRequestHelper from '../Helper/AuthHttpRequestHelper';
import BFFPrivateHttpRequestHelper from '../Helper/BFFPrivateHttpRequestHelper';
const AuthUrl = process.env.REACT_APP_API_ENTITY_MEMBER_AUTH_URL
//Auth에서 사용하는 Service를 모두 가져옴.
const AuthService =  { 
    //로그인 동작으로 토큰을 얻는다.
    getTokensByIdPass:(body) => {
        return BFFHttpRequestHelper.post("/auth/id",body)
    },
    //아이디 찾기 인증번호 발급
    sendSMSCodeForFindId:(body) => {
        return BFFHttpRequestHelper.post('/find-id/issue-authcode',body)
    },
    //아이디 찾기 인증번호 재발급
    resendSMSCodeForFindId:(body) => {
        return BFFHttpRequestHelper.post('/find-id/reissue-authcode',body)
    },
    //아이디 찾기 verification
    verificationFindIdBySms:(body) => {
        return BFFHttpRequestHelper.post('/find-id/verify-authcode',body)
    },
    //아이디 확인
    findIdByAuthVerification:(authToken) => {
        return BFFHttpRequestHelper.findIdRequest('/find-id',authToken)
    },
    //비밀번호 변경 인증번호 발급
    sendSMSCodeForResetPassword:(body) => {
        return BFFHttpRequestHelper.post('/reset-password/issue-authcode',body)
    },
    //비밀번호 변경 인증번호 검증
    verificationResetPasswordBySms:(body) => {
        return BFFHttpRequestHelper.post('/reset-password/verify-authcode',body)
    },
    //비밀번호 변경 인증번호 재발급
    resendSMSCodeForResetPassword:(body) => {
        return BFFHttpRequestHelper.post('/reset-password/reissue-authcode',body)
    },
    //비밀번호 리셋
    resetPasswordAuthVerification:(body,authToken) => {
        return BFFHttpRequestHelper.resetPasswordRequest('/reset-password',body,authToken)
    },

    CheckIdreduplication:(body) => {
        return BFFHttpRequestHelper.post("/register/validation-id",body)
    },
    withDrawService : (idx) => {
        return BFFPrivateHttpRequestHelper.deleteServiceUseage("/me/service/"+idx)
    },
    withdrawAcoount : () => {
        return BFFHttpRequestHelper.delete("/me")
    },

    //회원가입
    JoinUs:(body) => {
        return BFFHttpRequestHelper.post("/register",body)
    },
    Login : () => {
        return AuthHttpRequestHelper.getMe()
    },
    getCookie:()=>{
        const cookies = new Cookies
        const token = cookies.get("access_token")
        return token
    },
    setAccessToken:(tokenValue,date) => {
        const cookies = new Cookies
        cookies.set("access_token",tokenValue,{
            path : '/',
            expires:date,
            domain:process.env.REACT_APP_COOKIE_SHARE_URL
        })
    },
    setRefreshToken:(tokenValue,date) => {
        const cookies = new Cookies
        cookies.set("refresh_token",tokenValue,{
            path : '/',
            expires:date,
            domain:process.env.REACT_APP_COOKIE_SHARE_URL
        })
    },
    getUserInfo:async() => {
        try{
            return await AuthService.getAccessTokenByCookie()
        }catch(error){
        }
    },
    //Cookie로 Token를 얻는다.
    getAccessTokenByCookie: async() =>{
        const cookies = new Cookies
        const AccessToken = cookies.get('access_token')
        return AccessToken
    },
    //사용자 로그아웃 작업
    RequestLogout:async(redirect)=>{
        let cookies = new Cookies;
        cookies.remove('access_token',{
            path : '/',
            domain:process.env.REACT_APP_COOKIE_SHARE_URL
        })
        cookies.remove('refresh_token',{
            path : '/',
            domain:process.env.REACT_APP_COOKIE_SHARE_URL
        })
        window.location.replace('/login' + "?redirect="+ redirect)
    },
    //모든 Cookie delete
    DeleteAllCookies:async()=>{
        let cookies = new Cookies;
        cookies.remove('access_token',{
            path : '/',
            domain:process.env.REACT_APP_COOKIE_SHARE_URL
        })
        cookies.remove('refresh_token',{
            path : '/',
            domain:process.env.REACT_APP_COOKIE_SHARE_URL
        })
    },
};

export default AuthService