import React, { useEffect, useState ,useContext} from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import AuthService from '../AuthService';
import Cryptoservice from '../Cryptoservice';
import availability_disable from '../../assets/icons/availability_disable.svg'
import availability_enable from '../../assets/icons/availability_enable.svg'
import Modal from '../../Layout/Modals/Modal';
import { QueryContext } from '../../App';

const NewPassCheck = () => {
    const query = useContext(QueryContext);
    const navigate = useNavigate()
    const location = useLocation();
    const [pass,setpass] = useState('')
    const [passEqualCheck,setPassEqualCheck] = useState(false)
    //알림 모달
    const [modalStatus,setModalStatus] = useState(0)
    const [modal,setModal] = useState(false)
    const [modal_title,setmodal_title] = useState('')
    const [modal_contents,setmodal_contents] = useState('')

    //정규식
    const closeModalView = () => {
        if(modalStatus === 1){
            setModal(false)
            navigate(-1,{replace:true})
        }else if(modalStatus === 2){
            setModal(false)
            navigate("/"+query[0],{replace:true})
        }else{
            setModal(false)
        }
        setModalStatus(0)
    }
    const passwordChange = async() => {
        try{
            const data = {
                password:Cryptoservice.encrypt(pass)
            }
            const result = await AuthService.resetPasswordAuthVerification(data,location.state.auth_token)
            navigate("../success",{replace:true})
        }catch(error){
            if(error.response.status === 409){
                setModalStatus(1)
                setmodal_title('알림')
                setmodal_contents('기존 비밀번호와 같습니다. 새로운 비밀번호로 다시 변경해주세요.')
                setModal(true)
            }else if(error.response.status === 401){
                setModalStatus(2)
                setmodal_title('알림')
                setmodal_contents('인증이 만료되어 로그인 페이지로 돌아갑니다.')
                setModal(true)
            }else{
                setModalStatus(0)
                setmodal_title('알림')
                setmodal_contents('기타 오류입니다. 지속시 관리자에게 문의해주세요.')
                setModal(true)
            }
        }
    }
    const setPassword = (data) => {
        checkPass(data)
        setpass(data)
    }
    const checkPass = (data) => {
        if(data === location.state.password){
            setPassEqualCheck(true)
        }else{
            setPassEqualCheck(false)
        }
    }
    return (
        <div className='new-password-check-page'>
            <p className='info'>비밀번호 변경</p>
            <p className='sub-info'>방금 입력하신 비밀번호를 한번 더 입력해주세요.</p>
            <div className='items item-first last-item'>
                <div className='input-colum-title'>
                    <p>비밀번호</p>
                </div>
                <input type='password' className='input-colum' onChange={(event)=>{
                    const data = event.target.value
                    setPassword(data)
                }} placeholder='비밀번호를 입력해주세요'></input>
                {!passEqualCheck && pass?
                <div className='input-colum-comment'>
                    <div className='no-see'>
                        <p>직전에 입력한 비밀번호와 다릅니다.</p>
                    </div>
                </div>:<></>
                }
            </div>
            <button disabled = {pass === location.state.password ? false:true} onClick={() => passwordChange()}>다음</button>
            {modal? <Modal
                title={modal_title}
                contents={modal_contents}
                closeModal = {closeModalView}
                ></Modal>:<></>}
        </div>
    );
};

export default NewPassCheck;