import React, { useEffect } from 'react';
import '../SmsCheck.css'
import { useLocation } from 'react-router-dom';
import VerificateCodeComponent from '../../../modules/GlobalComponent/VerificateCodeComponent';
import FindIdSmsCheckViewModel from './FindIdSmsCheckViewModel';

const FindIdSmsCheck = () => {
    const {reSendSms,checkValueEnable} = FindIdSmsCheckViewModel.FindIdSmsCheckHook()

    return (
        <VerificateCodeComponent setValidateion={function (data) {
            checkValueEnable({
                code: data
            });
        } } resendCode={function () {
            reSendSms()
        } }></VerificateCodeComponent>
    );
};

export default FindIdSmsCheck;