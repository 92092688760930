import React, { useEffect } from 'react';

export const CheckBoxDisable = () => {
    return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="10" cy="10" r="10" fill="#3997FE"/>
        <path d="M4.5 10L8.5 14L15.5 7" stroke="white" strokeWidth="2"/>
    </svg>
    );
};
export const CheckBoxEnable = () => {
    return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="10" cy="10" r="10" fill="#E0E0E0"/>
        <path d="M4.5 10L8.5 14L15.5 7" stroke="white" strokeWidth="2"/>
    </svg>
    );
};
export const SecretCircle = () => {
    return(
    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="6" cy="6" r="6" fill="#757575"/>
    </svg>
    );
}
export const PhoneSelector = () => {
    return(
        <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M19.5 10.2692L13.0002 16.769L6.50032 10.2692" stroke="#BCBCBC" strokeWidth="1.5"/>
        </svg>
    );
}
export const CheckBox = ({onSetclick,status}) => {
    if(status){
        return (
            <svg onClick={()=>{onSetclick(false)}} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="10" cy="10" r="10" fill="#3997FE"/>
                <path d="M4.5 10L8.5 14L15.5 7" stroke="white" strokeWidth="2"/>
            </svg>
            );
    }else{
        return (
            <svg onClick={()=>{onSetclick(true)}} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="10" cy="10" r="10" fill="#E0E0E0"/>
                <path d="M4.5 10L8.5 14L15.5 7" stroke="white" strokeWidth="2"/>
            </svg>
        );
    }

};