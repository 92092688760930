import React, { useState } from 'react';
import style from './RecertificationCodeAuth.module.css'
import Modal from '../../../../Layout/Modals/Modal';
import Timer from '../../../../modules/Timer/Timer';
import { useLocation } from 'react-router-dom';
const RecertificationCodeAuth = () => {
    // Data 관련

    // 타이머 관련
    const [restarter,setRestarter] = useState(false)
    const [startTimer,setStartTimer] = useState(true)
    // Alert 관련
    const [alertmodal,setAlertModal] = useState(false)
    const [alertData,setAlertData] = useState({
        status : null,
        title :"",
        contents : ""
    })
    return (
        <div className={style['sms_page']}>
            <p className={style['info']}>인증번호 입력</p>
            <p className={style['sub-info']}>문자메시지로 수신 받은 인증번호 6자리를 아래 칸에 입력 후 [인증하기] 버튼을 눌러주세요.</p>
            <div className={style['items']}>
                <div className={style['input-colum-title']}>
                    <p>인증번호</p>
                </div>
                <input type='text' className={style['input-colum']} onChange={(event)=>{
                    const data = event.target.value
                    // setcertificationNumberValue(data)
                }} placeholder='인증번호를 입력해주세요'></input>
                <div className={style['input-colum-comment']}>
                 <Timer
                    endtimer = {()=>{}}
                    setStarted={()=>{setRestarter(false);}}
                    restart={restarter}
                ></Timer>
                    <div>
                        <p className={style['sms-resend']} onClick={async () => {setRestarter(true)}}>재전송</p>
                        <hr></hr>
                    </div>
                </div>
            </div>
            <div className={`${style['items']} ${style['last-item']}`}>
                <div className={style['comments']}>
                    <ol>
                        <li className={style['title']}>※ 문자메시지를 받지 못한 경우</li>
                        <li className={style['content']}>휴대전화의 차단메시지함을 확인하시거나 가입한 통신사로 문자 차단 서비스를 이용하고 있는지 확인해주세요.</li>
                    </ol>
                </div>
            </div>
            <button onClick={() => {}}>인증하기</button>
            {alertmodal? <Modal
                title={alertData.title}
                contents={alertData.contents}
                closeModal = {()=>{setAlertModal(false)}}
                ></Modal>:<></>}
        </div>
    );
};

export default RecertificationCodeAuth;