import axios from 'axios';
import React, { createContext, useContext, useEffect, useState } from 'react';
import AuthService from './AuthService';

const AuthContext = React.createContext({})
export const AuthProvider = ({children}) => {
    const [user, setUser] = useState()
    useEffect(() => {
        async function loadUserFromCookies() {
            const token = AuthService.getCookie('access_token')
            axios.defaults.headers.common['Authorization'] = "Bearer " + token
            if (!token) {
                setUser(null);
            }else{
                var result = null;
                try{
                    result = await AuthService.Login()                    
                }catch(error){
                    console.error(error)
                }finally { 
                    setUser(result)         
                }
            }
        }
        loadUserFromCookies()
    },[])

    return (
        <AuthContext.Provider value={{ isAuthenticated: !!user, user }}>
            {children}
        </AuthContext.Provider>
    )
};

export const useAuth = () => useContext(AuthContext)