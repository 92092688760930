import React from 'react';
import '../SmsCheck.css'
import VerificateCodeComponent from '../../../modules/GlobalComponent/VerificateCodeComponent';
import ResetPasswordCheckViewModel from './ResetPasswordCheckViewModel';
const ResetPasswordCheck = () => {
    const {reSendSms,checkValueEnable} = ResetPasswordCheckViewModel.ResetPasswordSmsCheckHook()

    return (
        <VerificateCodeComponent setValidateion={function (data) {
            checkValueEnable({
                code: data
            });
        } } resendCode={function () {
            reSendSms()
        } }></VerificateCodeComponent>
    );
};

export default ResetPasswordCheck;