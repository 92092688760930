import moment from 'moment/moment';
import React, { useContext, useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import AuthService from '../AuthService';
import { QueryContext } from '../../App';
const FindIdSuccess = () => {
    const query = useContext(QueryContext);
    const navigate = useNavigate()
    const location = useLocation();
    const [id,setId] = useState('')
    const [reg,setReg] = useState('')
    useEffect(()=>{
        getUserId()
    },[])
    const getUserId = async() => {
        setId(location.state?.id ? location.state.id : "확인불가")
        setReg(location.state?.regular_reg_at ? moment(location.state.regular_reg_at).format('YYYY년MM월DD일') : "확인불가")
    }
    return (
        <div className='find-id-success-page'>
            <div className='success-logo'>
                <svg className='changedone' width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="40" cy="40" r="40" fill="#F2F4F7"/>
                <rect width="8.79561" height="22.3545" rx="3" transform="matrix(0.709405 -0.704801 0.709405 0.704801 19.168 40.2754)" fill="#3997FE"/>
                <rect width="8.79561" height="36.113" rx="3" transform="matrix(0.709482 0.704724 -0.709482 0.704724 54.5898 24.5088)" fill="#3997FE"/>
                </svg>
            </div>
            <div className='items'>
                <p className='info'>아이디 찾기 완료</p>
                <p className='sub-info'>가입한 아이디를 확인해주세요.</p>
                <div>
                    <p className='ID'>{id?id:"-"}</p>
                    <p className='REGDATE'>가입날짜 : {reg}</p>
                </div>
            </div>
            <button onClick={() =>{
                const redirectItem = sessionStorage.getItem('redirectInfo')
                if(redirectItem === "null" || redirectItem === null){
                    window.location.replace("http://www.superbin.co.kr")
                }else{
                    if(redirectItem.startsWith("http://") || redirectItem.startsWith("https://")){
                        window.location.replace(redirectItem)
                    }else{
                        window.location.replace("http://"+redirectItem)
                    }
                }
                }}>로그인하기</button>
        </div>
    );
};
export default FindIdSuccess;