import React, { useEffect, useState } from 'react';
import Modal from '../../Layout/Modals/Modal';
import MainLogoImg from '../../assets/icons/login_logo.png';
import AuthService from '../AuthService';
import './Login.css'
import Cryptoservice from '../Cryptoservice';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Assitance from '../../Layout/Assitance/Assitance';
import Hnb from '../../Layout/Hnb/hnb';
import { QueryContext } from '../../App';
import { useContext } from 'react';
import jwtDecode from 'jwt-decode';
const Login = () => {
    const [checklist,setchecklist] = useState(false)
    const [modal,setModal] = useState(false)
    const [modal_title,setmodal_title] = useState('')
    const [modalContents,setmodalContents] = useState('')
    const [idValue,setidValue] = useState()
    const [passValue,setpassValue] = useState()
    const location = useLocation();
    const queryParam = new URLSearchParams(location.search)
    const value = useContext(QueryContext);
    useEffect(()=>{
        AuthService.DeleteAllCookies()
        sessionStorage.removeItem('redirectInfo')
        sessionStorage.removeItem('byLogin')
    })
    const saveRedirect = () => {
        sessionStorage.setItem('redirectInfo',queryParam.get('redirect'))
        sessionStorage.setItem('byLogin','true')
    }
    const Login = async () =>{
        if(checkValueIn()){
            const data = {
                id:idValue,
                password:passValue
            }
            try{
                const result = await AuthService.getTokensByIdPass(data)
                //refreshToken Decode , get Date
                const refreshDecoded = jwtDecode(result.refresh_token);
                var refreshTokendate = new Date(refreshDecoded.exp * 1000);
                //accessToken Decode , get Date
                const accessTokenDecoded = jwtDecode(result.access_token);
                var accessTokendate = new Date(accessTokenDecoded.exp * 1000);
                AuthService.setAccessToken(result.access_token,accessTokendate)
                AuthService.setRefreshToken(result.refresh_token,refreshTokendate)
                if(queryParam.get('redirect')){
                    if(queryParam.get('redirect').startsWith("https://") || queryParam.get('redirect').startsWith("http://")){
                        window.location.replace(queryParam.get('redirect'))
                    }else{
                        window.location.replace("https://"+queryParam.get('redirect'))
                    }
                }
            }catch(error){
                setmodal_title('알림')
                setmodalContents('로그인 아이디 혹은 비밀번호가 틀립니다.')
                setModal(true)
            }
        }
    }
    const setId = (data) =>{
        setidValue(data)
    }
    const setPass = (data) => {
        const convert = Cryptoservice.encrypt(data)
        setpassValue(convert)
    }
    const closeModalView = () => {
        setModal(false)
    }
    const checkValueIn = () =>{
        if(!idValue){
            setmodal_title('알림')
            setmodalContents('아이디를 입력해주세요.')
            setModal(true)
            return false
        }else if(!passValue){
            setmodal_title('알림')
            setmodalContents('비밀번호를 입력해주세요.')
            setModal(true)
            return false
        }else{
            return true
        }
    }
    return (
        <div className='login'>
            <div className='items'>
                <div className='input-colum-title'>
                    <p>아이디</p>
                </div>
                <input type='text' value={idValue} className='input-colum' onChange={(event)=>{
                    const data = event.target.value
                    setId(data)
                }}onBlur={()=>{
                    let trimString = idValue.trim()
                    setId(trimString)
                }}
                 placeholder='아이디를 입력해주세요'></input>
            </div>
            <div className='items last-item'>
                <div className='input-colum-title'>
                    <p>비밀번호</p>
                </div>
                <input className='input-colum' type='password' onChange={(event)=>{
                    const data = event.target.value
                    setPass(data)
                }} placeholder='비밀번호를 입력해주세요'></input>
            </div>
            <button onClick={()=>Login()}>들어가기</button>
            <div className='bottom_box'>
                <div className='left'>
                <Link onClick={()=>{saveRedirect()}} to={'/join'}><p>회원가입</p></Link> 
                </div>
                <div className='right'>
                    <Link onClick={()=>{saveRedirect()}} to= {'/find-account/id'}><p>아이디 찾기</p></Link>
                    <p>|</p>
                    <Link onClick={()=>{saveRedirect()}} to={'/find-account/password'}><p>비밀번호 변경</p></Link>
                </div>
            </div>
            {modal? <Modal
                title={modal_title}
                contents={modalContents}
                closeModal = {closeModalView}
                ></Modal>:<></>}
        </div>
    );
};
export default Login;