import moment from 'moment/moment';
import React, { useContext, useEffect, useState } from 'react';
import JoinUsService from '../JoinUsService';
import { useNavigate } from 'react-router-dom';
import AlertContext from '../../../modules/Modal/AlertModal/AlertContext';

const PrivacyTerms = () => {
    const {alert:alertComp} = useContext(AlertContext)
    const navigate = useNavigate()  
    const [contents , setcontents] = useState()
    const [modAt,setModAt] = useState()
    useEffect(()=>{
      getPrivateTerm()
    },[])
    const getPrivateTerm = async() =>{
      try{
        const result = await JoinUsService.getPrivateTerm()
        setModAt(result.announce_at)
        setcontents(result.content)
      }catch(error){
        await alertComp('오류 발생', '이용에 불편을 드려 죄송합니다. 서버 오류가 발생하였습니다. 계속해서 문제가 발생하는 경우 수퍼빈 카카오톡 채널로 문의 바랍니다.',undefined,'메인으로 돌아가기')
        navigate(-1)
      }
    }
      return (
      <>
      <div className='service-term-page'>
        <div className='title-box'>
          <p>개인정보 처리방침</p>
          <p>시행일자 : {moment(modAt).format("YYYY.MM.DD")}</p>
        </div>
        <div className='ck-content' dangerouslySetInnerHTML={{__html:contents}}></div>
      </div>
      </>
      );
};

export default PrivacyTerms;