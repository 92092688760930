// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".AgreeTerm_root__Q-WZW{display: flex; align-items: center; padding-bottom: 28px;}\n.AgreeTerm_root__Q-WZW > p{font-weight: 600; font-size: 17px; line-height: 24px; cursor: pointer;}\n.AgreeTerm_root__Q-WZW > svg{cursor: pointer; margin-right: 12px;}\n", "",{"version":3,"sources":["webpack://./src/modules/AgreeTerm/AgreeTerm.module.css"],"names":[],"mappings":"AAAA,uBAAM,aAAa,EAAE,mBAAmB,EAAE,oBAAoB,CAAC;AAC/D,2BAAU,gBAAgB,EAAE,eAAe,EAAE,iBAAiB,EAAE,eAAe,CAAC;AAChF,6BAAY,eAAe,EAAE,kBAAkB,CAAC","sourcesContent":[".root{display: flex; align-items: center; padding-bottom: 28px;}\n.root > p{font-weight: 600; font-size: 17px; line-height: 24px; cursor: pointer;}\n.root > svg{cursor: pointer; margin-right: 12px;}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "AgreeTerm_root__Q-WZW"
};
export default ___CSS_LOADER_EXPORT___;
