import React from 'react';
import { Navigate, Route, Routes } from 'react-router';
import AgreeTerms from '../Auth/JoinUs/AgreeTerms';
import JoinSuccess from '../Auth/JoinUs/JoinSuccess';
import JoinUs from '../Auth/JoinUs/JoinUs';
import Guard from '../Auth/JoinUs/NiceID/Guard';
import NiceGuardTerms from '../Auth/JoinUs/NiceID/NiceGuardTerms';
import NiceGuardValidation from '../Auth/JoinUs/NiceID/NiceGuardValidation';
import NiceTerms from '../Auth/JoinUs/NiceID/NiceTerms';
import NiceValidations from '../Auth/JoinUs/NiceID/NiceValidations';
import PrivacyTerms from '../Auth/JoinUs/Terms/PrivacyTerms';
import ServiceTerms from '../Auth/JoinUs/Terms/ServiceTerms';
import JoinIdValue from '../Auth/JoinUs/Validations/JoinIdValue';
import JoinPassCheck from '../Auth/JoinUs/Validations/JoinPassCheck';
import JoinPassValue from '../Auth/JoinUs/Validations/JoinPassValue';
import OptionalValue from '../Auth/JoinUs/Validations/OptionalValue';
import SexValue from '../Auth/JoinUs/Validations/SexValue';
import SmsCheck from '../Auth/SMScheck/SmsCheck';

function JoinRouter() {
    //회원가입에 대한 Router
    //User Router 의 경우 , /join 로 시작
    return (
        <>
        <Routes>
            <Route path="" element={<AgreeTerms></AgreeTerms>}></Route>
            <Route path="id" element={<JoinIdValue></JoinIdValue>}></Route>
            <Route path="password" element={<JoinPassValue></JoinPassValue>}></Route>
            <Route path="check" element={<JoinPassCheck></JoinPassCheck>}></Route>
            <Route path="sex" element={<SexValue></SexValue>}></Route>
            <Route path="optional" element={<OptionalValue></OptionalValue>}></Route>
            <Route path="success" element={<JoinSuccess></JoinSuccess>}></Route>
            <Route path="niceterms" element={<NiceTerms></NiceTerms>}></Route>
            <Route path="nicecertification" element={<NiceValidations></NiceValidations>}></Route>
            <Route path="guardcertification" element={<NiceGuardValidation></NiceGuardValidation>}></Route>
            <Route path="guard" element={<Guard></Guard>}></Route>
            <Route path="guardterms" element={<NiceGuardTerms></NiceGuardTerms>}></Route>
            <Route path="sms" element={<SmsCheck></SmsCheck>}></Route>
            <Route path="terms">   
                <Route path="" element={<Navigate to="personal" replace/>}></Route>
                <Route path="personal" element={<PrivacyTerms></PrivacyTerms>}></Route>
                <Route path="service" element={<ServiceTerms></ServiceTerms>}></Route>        
            </Route>
        </Routes>
        </>
    );
}

export default JoinRouter;