import React, { useEffect, useState } from 'react';
import RecertificationVerificationViewModel from './RecertificationVerificationViewModel';
import MvnoModal from '../../../../Layout/Modals/MvnoModal';
import Modal from '../../../../Layout/Modals/Modal';
import { PhoneSelector, SecretCircle } from '../../../../assets/svgs/icons';
import style from './RecertificationVerification.module.css'
import { TelecomBtn } from '../../../../assets/layouts/button';
const RecertificationVerification = () => {
    const [device,setDevice] = useState(false)
    //알림 모달
    const [alertmodal,setAlertModal] = useState(false)
    const [alertData,setAlertData] = useState({
        status : null,
        title :"",
        contents : ""
    })
    //NVMO 모달
    const [nvmoModal,setNvmoModal] = useState(false)
    //selector view 관련
    const [selectView,setSelectView] = useState(false)
    // 핸드폰 인증 관련
    const telecomName = ["","SKT","KT","LGU+","","알뜰폰 SKT","알뜰폰 KT","알뜰폰 LGU+"]
    const [inputFocus,setInputFocus] = useState(false)
    // Input 값
    const valueListKey = ["name","phone","idCardFront","sex"]
    const [phoneHead,setPhoneHead] = useState('010')
    const [checkValue,setCheckValue] = useState(false)
    const [telecom,setTelecom] = useState('')
    const [value,setValue] = useState({
        name :"",
        phone : "",
        idCardFront : "",
        sex : ""
    })

    const alertModalOpen = (status,title,contents) => {
        setAlertData({
            status : status,
            title : title,
            contents : contents
        })
    }
    const toSMSCode = () => {

    }
    useEffect(()=>{
        let check = true
        for(const valueKey of valueListKey){
            if(!value[valueKey]) {
                check = false
            }
            if(!phoneHead){
                check = false
            }
            if(!telecom){
                check = false
            }
        }
        setCheckValue(check)
    },[phoneHead,value])
    return (
        <>
        <div className={style['nice-valudation-page']}>
            <p className={style['info']}>수퍼빈 회원 인증</p>
            <p className={style['sub-info']}>이름, 생년월일, 통신사, 휴대전화 번호를 순서대로 입력하세요.</p>
            <div className={`${style['items']} ${style['item-first']}`}>
                <div className={style['input-colum-title']}>
                    <p>이름</p>
                </div> 
                <input type='text' value={value.name} className={style['input-colum']} onChange={(event)=>{
                    const data = {...value}
                    data.name = event.target.value
                    data.name = data.name.replace(/[\{\}\[\]\/?.,;:|\)*~`!^\-_+<>@\#$%&\\\=\(\'\"]/gi,'')
                    setValue(data)
                }} onBlur={()=>{
                    const data = {...value}
                    const checkNameData = RecertificationVerificationViewModel.checkNameData(data.name)
                    if(checkNameData){
                        data.name = checkNameData
                        setValue(data)
                    }else{
                        alertModalOpen(0,"알림","이름을 정확히 입력해주세요.")
                    }
                    }} placeholder='이름을 입력해주세요'></input>
                <div className={style['input-colum-comment']}>
                    <p>본인 이름을 한글 또는 영문으로 입력해주세요. (통신사 가입 명의)</p>
                </div>
            </div>
            <div className={style['items']}>
                <div className={style['input-colum-title']}>
                    <p>생년월일/성별</p>
                </div>
                <div className={style['input-double-flex']}>
                    <input type='text' value={value.idCardFront} className={style['input-colum']} onChange={(event)=>{
                        const data = {...value}
                        const eventData = RecertificationVerificationViewModel.replaceIdCardData(event.target.value)
                        if(eventData || eventData == undefined){
                            data.idCardFront = eventData
                            setValue(data)
                        }
                    }} placeholder='주민번호 앞 번호'></input>
                    <div className={style['id-card-number']}>
                        <p>-</p>
                        <input type='text' value={value.sex} className={style['input-colum']} onChange={(event)=>{
                            var data = {...value}
                            const eventData = RecertificationVerificationViewModel.replaceSexNumberData(event.target.value)
                            if(eventData){
                                data.sex = eventData
                                setValue(data)
                            }
                        }} placeholder='1'></input>
                        <SecretCircle className={style["circle"]}></SecretCircle>
                        <SecretCircle className={style["circle"]}></SecretCircle>
                        <SecretCircle className={style["circle"]}></SecretCircle>
                        <SecretCircle className={style["circle"]}></SecretCircle>
                        <SecretCircle className={style["circle"]}></SecretCircle>
                        <SecretCircle className={style["circle"]}></SecretCircle>
                    </div>
                </div>
                <div className={style['input-colum-comment']}>
                    <p>주민번호 앞 번호(생년월일), 뒷 번호(성별) 첫 자리까지만 입력해주세요.</p>
                </div>
            </div>
            <div className={style['items']}>
                <div className={style['input-colum-title']}>
                    <p>통신사</p>     
                </div>
                <div className={style['telecom-container']}>
                    <TelecomBtn title='SKT' status={telecom === 1 ? 'true':'false'} onSetClick={()=>{setTelecom(1)}}></TelecomBtn>
                    <TelecomBtn title='KT' status={telecom === 2} onSetClick={()=>{setTelecom(2)}}></TelecomBtn>
                    <TelecomBtn title='LGU+' status={telecom === 3} onSetClick={()=>{setTelecom(3)}}></TelecomBtn>
                    <TelecomBtn title={telecom >= 5 ? telecomName[telecom]:"알뜰폰"} status={telecom >= 5} onSetClick={()=>{setNvmoModal(true)}}></TelecomBtn>
                </div>
            </div>
            <div className={style['items']}>
                <div className={style['input-colum-title']}>
                    <p>휴대전화 번호</p>
                </div>
                <div className={style['input-double']}>
                    {!device? <div 
                    onClick={() => {setSelectView(!selectView)}} className={phoneHead? `${style['selector']} ${style['selected']}`: style['selector']} >
                        {phoneHead? phoneHead:'010'} &nbsp;&nbsp;&nbsp;
                        <PhoneSelector alt='dropdown'></PhoneSelector>
                        {selectView ? 
                        <ul className={style["phone-list"]}>
                            <li onClick={()=>{setPhoneHead('010')}} className="phone-item"><p>010</p></li>
                            <li onClick={()=>{setPhoneHead('011')}} className="phone-item"><p>011</p></li>
                            <li onClick={()=>{setPhoneHead('019')}} className="phone-item"><p>019</p></li>
                        </ul>:<></>}
                    </div>:<select onChange={(event)=>{
                        setPhoneHead(event.target.value)
                    }} className='input-selector'>
                        <option value='010'>010&nbsp;&nbsp;&nbsp;</option>
                        <option value='011'>011&nbsp;&nbsp;&nbsp;</option>
                        <option value='019'>019&nbsp;&nbsp;&nbsp;</option>
                    </select>}  
                    <input type='text' value={value.phone} className={style['input-colum']} onChange={(event)=>{
                        const data = {...value}
                        let replaceData = event.target.value
                        replaceData = replaceData.replace(/[^0-9]/g, '')
                        replaceData = replaceData.replace(/^(\d{3,4})(\d{4})$/, `$1-$2`)
                        data.phone = replaceData
                        setValue(data)}}
                        onFocus = {()=>setInputFocus(true)}
                        onBlur = {()=>setInputFocus(false)} 
                        placeholder='휴대전화 번호를 입력해주세요'></input>
                </div>
                <hr className={inputFocus? style["input-focus-true"]:style["input-focus-false"]}></hr>
                <div className={style['input-colum-comment']}>
                    <p>휴대전화 번호는 숫자로만 입력해주세요.</p>
                </div>
            </div>
            <button disabled={!checkValue} onClick={() => toSMSCode()}>인증 문자 받기</button>
            {alertmodal? <Modal
                title={alertData.title}
                contents={alertData.contents}
                closeModal = {setAlertModal(false)}
                ></Modal>:<></>}
            {nvmoModal? <MvnoModal SelectData={(data)=>{setTelecom(data); setNvmoModal(false)}}></MvnoModal>:<></>}
        </div>
        </>
    );
};

export default RecertificationVerification;