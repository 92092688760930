import React, { useEffect } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import Withdraw from '../Auth/Withdraw/Withdraw';
import PrivateGuard from './PrivateGuard';
import InputPassword from '../Auth/InputPassword/InputPassword';
import CancelAffiliateService from '../Auth/CancelAffiliateService/CancelAffiliateService';

function WithdrawRouter() {
    const location = useLocation()
    const queryParam = new URLSearchParams(location.search)
    return (
        <Routes>
            <Route path='' element={<PrivateGuard><Withdraw /></PrivateGuard>}></Route>
            <Route path='input-password' element={<InputPassword />}></Route>
            <Route path='cancel-affiliate-service' element={<CancelAffiliateService />}></Route>
        </Routes>
    );
}

export default WithdrawRouter;