import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Modal from '../../../Layout/Modals/Modal';
import availability_disable from '../../../assets/icons/availability_disable.svg'
import availability_enable from '../../../assets/icons/availability_enable.svg'
import JoinPager from '../../../Layout/JoinPager/JoinPager';
import AuthService from '../../AuthService';

const JoinIdValue = () => {
    const navigate = useNavigate()
    const location = useLocation();
    const [idValue,setIdValue] = useState('')
    const [IdStringCheck,setIdStringCheck] = useState(false)
    const [IdLengthCheck,setIdLengthCheck] = useState(false)
    //알림 모달
    const [modal,setModal] = useState(false)
    const [modal_title,setmodal_title] = useState('')
    const [modal_contents,setmodal_contents] = useState('')
    useEffect(()=>{
        checkEnableIDValidationButton()
    },[idValue])
    //정규식
    const closeModalView = () => {
        if(modal_title === "완료"){
            setModal(false)
            window.location.href = '/'
        }else{
            setModal(false)
        }
    }
    const IdValidation = (data) => {
        const regexspace = /\s+/gi;
        if(!regexspace.test(data)){
            setIdValue(data)
        }
    }
    const checkEnableIDValidationButton = () => {
        const regex = /^[a-zA-Z0-9]{1,}$/g
        const regexNum = /[0-9]/g
        if(idValue.length >= 5 && idValue.length <= 20){
            setIdLengthCheck(true)
        }else{
            setIdLengthCheck(false)
        }
        if(regex.test(idValue) && regexNum.test(idValue)){
            setIdStringCheck(true)
        }else{
            setIdStringCheck(false)
        }
    }
    const checkIdreduplication = async() => {
        const body = {
            id:idValue
        }
        try{
            const result = await AuthService.CheckIdreduplication(body)
            nextPage()
        }catch(error){
            if(error.response.status === 409){
                setmodal_title('알림')
                setmodal_contents('이미 사용 중인 아이디입니다. 다른 아이디로 입력해주세요.')
                setModal(true)
                return false
            }else{
                setmodal_title('알림')
                setmodal_contents('기타 오류입니다. 지속시 관리자에게 문의해주세요.')
                setModal(true)
                return false
            }
        }
        
    }
    const nextPage = () => {
        if(location.state.guardion_name){
            navigate("../password",{state:{
                id : idValue,
                name : location.state.name,
                birthday:location.state.birthday,
                phone:location.state.phone,
                cicode : location.state.cicode,
                dicode : location.state.dicode,
                guardion_name:location.state.guardion_name,
                guardion_birthday : location.state.guardion_birthday,
                guardion_cicode : location.state.guardion_cicode,
                guardion_dicode : location.state.guardion_dicode
            }})
        }else{
            navigate("../password",{state:{
                id : idValue,
                name : location.state.name,
                birthday:location.state.birthday,
                cicode : location.state.cicode,
                dicode : location.state.dicode,
                phone:location.state.phone
            }})
        }
    }
    return (
        <div className='join-id-page'>
            <JoinPager
                num={2}
            ></JoinPager>
            <p className='info'>아이디 입력(필수 정보)</p>
            <p className='sub-info'>아래 칸에 사용하실 아이디를 입력 후 [다음] 버튼을 눌러주세요.</p>
            <div className='items item-first last-item'>
                <div className='input-colum-title'>
                    <p>아이디</p>
                </div>
                <input type='text' value={idValue} className='input-colum' onChange={(event)=>{
                    IdValidation(event.target.value)
                }}
                placeholder='아이디를 입력해주세요'></input>
                <div className='input-colum-comment'>
                    <div>
                        <img src={IdStringCheck ?availability_enable:availability_disable} alt="avility_string"></img>
                        <p>영문(대소문자 구분), 숫자 1개 이상</p>
                    </div>
                    <div>
                        <img src={IdLengthCheck ?availability_enable:availability_disable} alt="avility_string"></img>
                        <p>5글자 이상 20자 이하</p>
                    </div>
                </div>
            </div>
            <button disabled = {IdStringCheck && IdLengthCheck ? false:true} onClick={() => checkIdreduplication()}>다음</button>
            {modal? <Modal
                title={modal_title}
                contents={modal_contents}
                closeModal = {closeModalView}
                ></Modal>:<></>}
        </div>
    );
};

export default JoinIdValue;