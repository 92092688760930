import React from 'react';
import { Navigate, Route, Routes } from 'react-router';
import Findidsuccess from '../Auth/Findid/FindIdSuccess';
import FindPass from '../Auth/FindPass/FindPass';
import FindID from '../Auth/Findid/FindId';
import AuthPrivateRouter from './AuthPrivateRouter';
import NewPass from '../Auth/FindPass/New_pass';
import SmsCheck from '../Auth/SMScheck/SmsCheck';
import New_Pass_Check from '../Auth/FindPass/NewPassCheck';
import FindPassSuccess from '../Auth/FindPass/FindPassSuccess';
import FindIdSmsCheck from '../Auth/SMScheck/FindId/FindIdSmsCheck';
import ResetPasswordCheck from '../Auth/SMScheck/ResetPassword/ResetPasswordCheck';
function UserRouter () {
    //아이디 찾기 , 비밀번호 찾기
    //User Router 의 경우 , /user 로 시작
    return (
        <>
        <Routes>
            <Route path="" element={<Navigate to="id" replace/>}></Route>
            <Route path="id">
                <Route path="" element={<FindID/>}></Route>
                <Route path="sms" element={<FindIdSmsCheck/>}></Route>
                <Route path="success" element={<Findidsuccess></Findidsuccess>}></Route>        
            </Route>
            <Route path="password">   
                <Route path="" element={<FindPass></FindPass>}></Route>        
                <Route path="sms" element={<ResetPasswordCheck/>}></Route>
                <Route path="change" element={<NewPass></NewPass>}></Route>
                <Route path="check" element={<New_Pass_Check></New_Pass_Check>}></Route>
                <Route path="success" element={<FindPassSuccess></FindPassSuccess>}></Route>
            </Route>
        </Routes>
        </>
    );
};
export default UserRouter;