import axios from 'axios';
import React from 'react';
import AuthService from '../Auth/AuthService';
const protocal = window.location.protocol+"//"
const API_BFF_APP_URL = process.env.REACT_APP_API_BFF_AUTH_URL

const BFFPrivateHttpRequestHelper =  {
    getPrivateToken: async (data) => {
        const token = AuthService.getCookie()
        const instance = axios.create({
            baseURL : protocal + API_BFF_APP_URL,
            headers : {
                Authorization: `Bearer ${token}`
            }
        })
        try{
            const response = await instance.post("/privacy-auth",data)
            return response.data
        }catch(error){
            throw error
        }
    },
    validatePrivateToken: async () => {
        const sessionStorageValue = sessionStorage.getItem('privacy_token')
        const token = sessionStorageValue ? sessionStorageValue : ''
        const instance = axios.create({
            baseURL : protocal + API_BFF_APP_URL,
            headers : {
                Authorization: `Bearer ${token}`
            }
        })
        try{
            const response = await instance.post('/privacy-auth/verify')
            return response
        }catch(error){
            throw error
        }
    },
    withdrawAccount: async (body) => {
        const sessionStorageValue = sessionStorage.getItem('privacy_token')
        const token = sessionStorageValue ? sessionStorageValue : ''
        const instance = axios.create({
            baseURL : protocal + API_BFF_APP_URL,
            headers : {
                Authorization: `Bearer ${token}`
            }
        })
        try{
            const response = await instance.delete('/me',{data : body})
            return response
        }catch(error){
            throw error
        }
    },
    deleteServiceUseage: async (url) => {
        const sessionStorageValue = sessionStorage.getItem('privacy_token')
        const token = sessionStorageValue ? sessionStorageValue : ''
        const instance = axios.create({
            baseURL : protocal + API_BFF_APP_URL,
            headers : {
                Authorization: `Bearer ${token}`
            }
        })
        try{
            const response = await instance.delete(url)
            return response
        }catch(error){
            throw error
        }
    },
    getMoreServiceData: async () => {
        const token = AuthService.getCookie()
        const instance = axios.create({
            baseURL : protocal + API_BFF_APP_URL,
            headers : {
                Authorization: `Bearer ${token}`
            }
        })
        try{
            const response = await instance.get("/me/service/more",null)
            return response.data
        }catch(error){
            throw error
        }
    },
    getRookieServiceData: async () => {
        const token = AuthService.getCookie()
        const instance = axios.create({
            baseURL : protocal + API_BFF_APP_URL,
            headers : {
                Authorization: `Bearer ${token}`
            }
        })
        try{
            const response = await instance.get("/me/service/rookie",null)
            return response.data
        }catch(error){
            throw error
        }
    },
    deleteMoreService: async () => {
        const token = AuthService.getCookie()
        const instance = axios.create({
            baseURL : protocal + API_BFF_APP_URL,
            headers : {
                Authorization: `Bearer ${token}`
            }
        })
        try{
            const response = await instance.delete("/me/service/more",null)
            return response.data
        }catch(error){
            throw error
        }
    },
    deleteRookieService: async () => {
        const token = AuthService.getCookie()
        const instance = axios.create({
            baseURL : protocal + API_BFF_APP_URL,
            headers : {
                Authorization: `Bearer ${token}`
            }
        })
        try{
            const response = await instance.delete("/me/service/rookie",null)
            return response.data
        }catch(error){
            throw error
        }
    },
};

export default BFFPrivateHttpRequestHelper;