import React, { useState } from 'react';
import './SmsModal.css'
import checkbox from '../../assets/icons/checkbox-sms.png'
import JoinUsService from '../../Auth/JoinUs/JoinUsService';
const SmsModal = ({closeModal}) => {
    const [first,setfirst] = useState('')
    const [second,setsecond] = useState('')
    const [third,setthird] = useState('')
    const [checkboxStatus,setcheckboxStatus] = useState(false)
    const Close = () => {
        closeModal()
    }
    const AppdownLoad = async(phone) => {
        const body = {
            phone_number:phone,
            is_terms_agreed:true
        }
        // await JoinUsService.postSmsAppdownload(body)
        closeModal()
    }
    return (
        <div className='sms_modal_wrap'>
            <div className='modal'>
                <div className='close'>
                    {/* <button onClick={Close}>X</button> */}
                    <svg onClick={Close} width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M9 9L31 31" stroke="#222222"/>
                    <path d="M31 9L9 31" stroke="#222222"/>
                    </svg>
                </div>
                <p className='title'>문자로 앱 설치 주소를 보내드려요</p>
                <p className='contents'>1600-6217번으로 발송됩니다. <br /> 문자가 도착하지 않을 경우 스팸함을 확인해주세요.</p>
                <div className='inputContainer'>
                    <input onChange={(event)=>{
                        const data = event.target.value
                        setfirst(data)
                        }}
                        className={first?"active":""} type="number" maxlength="3" />
                    <div className="line-box">
                        <div className="line"></div>
                    </div>
                    <input onChange={(event)=>{
                        const data = event.target.value
                        setsecond(data)
                        }} className={second?"active":""} type="number" maxlength="4" />
                    <div className="line-box">
                        <div className="line"></div>
                    </div>
                    <input onChange={(event)=>{
                        const data = event.target.value
                        setthird(data)
                        }} className={third?"active":""} type="number" maxlength="4" />
                    <button onClick={()=>{AppdownLoad(first+second+third)}} type="button" aria-hidden="true" disabled={first!== "" && second!== "" && third!== "" && !checkboxStatus? false:true}>전송</button>
                </div>
                <div onClick={() => {setcheckboxStatus(!checkboxStatus)}} className='checkContainer'>
                    <div className={checkboxStatus? 'imgbox active':'imgbox'}><img src={checkbox}></img></div>
                    <p>앱 다운로드 주소 전송을 위해 전화번호를 수집하며, 수집일로부터 3개월간 보관됩니다. 서비스 제공을 위한 최소한의 개인정보이므로, 동의를 해주셔야 서비스를 이용하실 수 있습니다.</p>
                </div>

            </div>
        </div>
    );
};

export default SmsModal;