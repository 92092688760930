// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".AlertModal_modal__73Rkl{\n    padding: 38px 32px 32px 32px;\n    max-width: 428px; \n    width: 100%;\n    background-color: white; \n    border: none; \n    border-radius: 20px;\n}\n.AlertModal_modal__73Rkl > .AlertModal_title__13Wu-{font-size: 20px; font-weight: 700; }\n.AlertModal_modal__73Rkl > .AlertModal_sub-title__v1Aou{padding-top: 10px; font-size: 18px; line-height: 24px;}\n.AlertModal_modal__73Rkl > .AlertModal_description__jrVZZ{padding-top: 10px; font-size: 18px; word-break: keep-all; line-height: 24px;}\n.AlertModal_modal__73Rkl > .AlertModal_btn-box__yCey7{display: flex; padding-top: 30px; gap: 16px;}\n.AlertModal_modal__73Rkl > .AlertModal_btn-box__yCey7 > div{flex: 1 1;}", "",{"version":3,"sources":["webpack://./src/modules/Modal/AlertModal/AlertModal.module.css"],"names":[],"mappings":"AAAA;IACI,4BAA4B;IAC5B,gBAAgB;IAChB,WAAW;IACX,uBAAuB;IACvB,YAAY;IACZ,mBAAmB;AACvB;AACA,oDAAgB,eAAe,EAAE,gBAAgB,EAAE;AACnD,wDAAoB,iBAAiB,EAAE,eAAe,EAAE,iBAAiB,CAAC;AAC1E,0DAAsB,iBAAiB,EAAE,eAAe,EAAE,oBAAoB,EAAE,iBAAiB,CAAC;AAClG,sDAAkB,aAAa,EAAE,iBAAiB,EAAE,SAAS,CAAC;AAC9D,4DAAwB,SAAO,CAAC","sourcesContent":[".modal{\n    padding: 38px 32px 32px 32px;\n    max-width: 428px; \n    width: 100%;\n    background-color: white; \n    border: none; \n    border-radius: 20px;\n}\n.modal > .title{font-size: 20px; font-weight: 700; }\n.modal > .sub-title{padding-top: 10px; font-size: 18px; line-height: 24px;}\n.modal > .description{padding-top: 10px; font-size: 18px; word-break: keep-all; line-height: 24px;}\n.modal > .btn-box{display: flex; padding-top: 30px; gap: 16px;}\n.modal > .btn-box > div{flex: 1;}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modal": "AlertModal_modal__73Rkl",
	"title": "AlertModal_title__13Wu-",
	"sub-title": "AlertModal_sub-title__v1Aou",
	"description": "AlertModal_description__jrVZZ",
	"btn-box": "AlertModal_btn-box__yCey7"
};
export default ___CSS_LOADER_EXPORT___;
