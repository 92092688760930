import React from 'react';
import './Modal.css'
const DescriptionModal = ({title,contents,btn_title,closeModal}) => {
    return (
        <div className='modal_wrap'>
            <div className='modal'>
                <div className='title'>
                    <p>{title}</p>
                </div>
                <div className='contents'>
                    {
                        contents.split("\n").map((text)=>{
                            return(<p key={text} dangerouslySetInnerHTML={{__html: text}}></p>);
                        })
                    }
                </div>
                <div>
                    <button onClick={closeModal}>{btn_title}</button>     
                </div>
            </div>
        </div>
    );
};

export default DescriptionModal;