import CryptoJS from "crypto-js";
const iv = process.env.REACT_APP_SECRET_CRYPTO_IV_VALUE
const key = process.env.REACT_APP_SECRET_CRYPTO_KEY_VALUE

const Cryptoservice = {
    encrypt: (pass) => {
        return CryptoJS.AES.encrypt(pass, CryptoJS.enc.Utf8.parse(key),
            {
            iv: CryptoJS.enc.Utf8.parse(iv),
            keySize: 256 / 32,
            mode:CryptoJS.mode.CBC,
            padding:CryptoJS.pad.Pkcs7
        }).toString();
    },
    decrypt: (text) => {
        const decipher = CryptoJS.AES.decrypt(text, CryptoJS.enc.Utf8.parse(key), {
            iv: CryptoJS.enc.Utf8.parse(iv),
            padding: CryptoJS.pad.Pkcs7,
            mode: CryptoJS.mode.CBC,
        })
        return decipher.toString(CryptoJS.enc.Utf8);
    }
};

export default Cryptoservice;