import axios from 'axios';
import React from 'react';
import { Cookies } from 'react-cookie'
const protocal = window.location.protocol+"//"
const API_BFF_APP_URL = process.env.REACT_APP_API_BFF_AUTH_URL

const AuthHttpRequestHelper = {
    getMe: async () => {
        const cookies = new Cookies();
        const token = cookies.get("access_token")
        const instance = axios.create({
            baseURL : protocal + API_BFF_APP_URL,
            headers : {
                Authorization: `Bearer ${token}`,
            }
        })
        try{
            const response = await instance.get('/me')
            return response.data
        }catch(error){
            if(error.response?.status === 401){
                const access_token = await AuthHttpRequestHelper.getTokenByrefreshToken()
                if(access_token){
                    try{
                        const reinstance = axios.create({
                            baseURL : protocal + API_BFF_APP_URL,
                            headers : {
                                Authorization: `Bearer ${access_token}`,
                            }
                        })
                        const result = await reinstance.get('/me')
                        return result.data
                    }catch(err){
                        console.error(err)
                    }
                }else{
                    throw error
                }
            }else{
                throw error
            }
        }
    },
    getTokenByrefreshToken: async () => {
        const cookies = new Cookies();
        const refresh_token = cookies.get("refresh_token")
        const refreshCall = axios.create({
            baseURL : protocal + API_BFF_APP_URL,
            headers : {
                Authorization: `Bearer ${refresh_token}`,
            }
        })
        try{
            const response = await refreshCall.post('/auth/refresh')
            return response.data.access_token
        }catch(error){
            return false
        }
      },
};

export default AuthHttpRequestHelper;