import React, { useContext, useEffect, useState } from 'react';
import style from './CancelAffiliateService.module.css'
import CancelAffiliateServiceViewModel from './CancelAffiliateServiceViewModel';
import MyContentsBoxLayout from '../../Layout/MyContent/MyContentsBox/MyContentsBoxLayout';
import ConfirmContext from '../../modules/Modal/ConfirmModal/ConfirmContext';
import { WithDrawMoreIcon, WithDrawRookieIcon } from '../../assets/icons/svgs';
import { useLocation, useNavigate } from 'react-router-dom';
import PrimaryButton from '../../modules/Button/PrimaryButton/PrimaryButton';
import TransparentButton from '../../modules/Button/TransparentButton/TransparentButton';
import moment from 'moment';

const CancelAffiliateService = () => {
    const navigate = useNavigate()
    const location = useLocation()
    const {userData,display} = CancelAffiliateServiceViewModel.SetDisplayStatusHook();
    const [withdrawBtnEnable , setWithdrawBtnEnable] = useState(false)
    const {superbinServiceStatus , setSuperbinMoreStatus , setSuperbinRookieStatus} = CancelAffiliateServiceViewModel.SetWithdrawServiceHook();
    const {confirm: confirmComp } = useContext(ConfirmContext);
    useEffect(()=>{
        withdrawBtnSet()
    },[display,superbinServiceStatus])
    const withdrawBtnSet = () => {
        let btnStatus = true
        if(display.superbinRookie.display){
            if(!superbinServiceStatus.superbinRookieDisabled){
                btnStatus = false
            }
        }
        if(display.superbinMore.display){
            if(!superbinServiceStatus.superbinMoreDisabled){
                btnStatus = false
            }
        }
        setWithdrawBtnEnable(btnStatus)
    }
    const onConfirmMore = async(idx) => {
        const result = await confirmComp('수퍼빈모아 해지하기','지역정보, 랭킹, 알림메시지, 앱설정 정보 등이 삭제되며, 수퍼빈모아 재인증 시 복구되지 않습니다. 수퍼빈모아 서비스를 해지하시겠습니까?',undefined,'서비스 해지','닫기')
        if(result){
            if(idx){setSuperbinMoreStatus(idx)}
        }
    }
    const onConfirmRookie = async(idx) => {
        const result = await confirmComp('수퍼빈루키 해지하기','수퍼빈루키 학생 정보와 보유 씨앗이 즉시 삭제되며, 수퍼빈루키 재인증 시 복구되지 않습니다. 수퍼빈루키 서비스를 해지하시겠습니까?',undefined,'서비스 해지','닫기')
        if(result){
            if(idx){setSuperbinRookieStatus(idx)}
        }
    }
    return (
        <MyContentsBoxLayout>
            <div className={style['page-start']}>
                <div className={style['root']}>
                    <div>
                        <p className={style['title']}>서비스 이용 해지</p>
                        <p className={style['description']}>{userData.name}님은 현재 아래 서비스에 인증되어 있습니다. 아래 서비스를 먼저 해지해 주셔야 회원 탈퇴가 가능합니다.</p>
                        <div className={style['item']}>
                            <ul className={style["service-list"]}>
                                {display.superbinMore.display ?<li>
                                    <div>
                                        <WithDrawMoreIcon />
                                        <div>
                                            <p>수퍼빈모아</p>
                                            <p className={style["consent_at"]}>{"(인증일자 : "+moment(display.superbinMore.consent_at).format("YYYY.MM.DD")+")"}</p>
                                        </div>
                                    </div>
                                    <TransparentButton 
                                        title={!superbinServiceStatus.superbinMoreDisabled ? '해지':"해지완료"}
                                        onClick={function () {
                                            onConfirmMore(display.superbinMore.idx)
                                        }}disabled={superbinServiceStatus.superbinMoreDisabled ? superbinServiceStatus.superbinMoreDisabled : false} />
                                </li>:<></>}
                                {display.superbinRookie.display ? 
                                <li>
                                    <div>
                                        <WithDrawRookieIcon />
                                        <div>
                                            <p>수퍼빈루키</p>
                                            <p className={style["consent_at"]}>{"(인증일자 : "+moment(display.superbinRookie.consent_at).format("YYYY.MM.DD")+")"}</p>
                                        </div>
                                    </div>
                                    <TransparentButton 
                                        title={!superbinServiceStatus.superbinRookieDisabled ? '해지':"해지완료"}
                                        onClick={function () {
                                            onConfirmRookie(display.superbinRookie.idx)
                                        }}disabled={superbinServiceStatus.superbinRookieDisabled ? superbinServiceStatus.superbinRookieDisabled : false} />
                                </li>:<></>}
                            </ul>
                        </div>
                        <div className={style['validate-end']}>
                            <PrimaryButton 
                            title={'취소하기'} 
                            disabled={false} 
                            onClick={function () {
                                navigate(-1)
                            }}></PrimaryButton>
                            <PrimaryButton
                            title={'회원 탈퇴하기'} 
                            disabled={!withdrawBtnEnable} 
                            onClick={function () {
                                navigate("/withdraw"+location.search,{replace:true})
                            }}></PrimaryButton>
                        </div>
                    </div>
                </div>
            </div>
        </MyContentsBoxLayout>
        
    );
};

export default CancelAffiliateService;