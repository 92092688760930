import React from 'react';
import { Navigate, Route, Routes } from 'react-router';
import RecertificationConsent from '../Auth/Recertification/NiceID/Consent/RecertificationConsent';
import RecertificationVerification from '../Auth/Recertification/NiceID/Verification/RecertificationVerification';
import RecertificationCodeAuth from '../Auth/Recertification/NiceID/CodeAuthentication/RecertificationCodeAuth';
import RecertificationSuccess from '../Auth/Recertification/Finish/Success/RecertificationSuccess';

function RecertificationRouter() {
    return (
        <Routes>
            <Route path="" element={<RecertificationConsent></RecertificationConsent>}></Route>
            <Route path="verification" element={<RecertificationVerification></RecertificationVerification>}></Route>
            <Route path="sms" element={<RecertificationCodeAuth></RecertificationCodeAuth>}></Route>
            <Route path="success" element={<RecertificationSuccess></RecertificationSuccess>}></Route>
        </Routes>
    );
}
export default RecertificationRouter;