import React, { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import AuthService from '../AuthService';
import { QueryContext } from '../../App';
const Logout = () => {
    const location = useLocation()
    const queryParam = new URLSearchParams(location.search)
    const queryValue = useContext(QueryContext)
    // navigate("/login"+queryValue[0],{replace:true})
    useEffect(()=>{
        doLogOut()
    },[])
    const doLogOut = async() => {
        try{
            await AuthService.RequestLogout(queryParam.get('redirect'))
        }catch(error){
        }
    }
    return (
        <div>
            <p>로그아웃 중입니다...</p>
        </div>
    );
};

export default Logout;