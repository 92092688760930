import React from 'react';
import BFFPrivateHttpRequestHelper from '../Helper/BFFPrivateHttpRequestHelper';

const MemberService = {
    withDrawAccount : (body) => {
        return BFFPrivateHttpRequestHelper.withdrawAccount(body)
    },
    getMoreServiceData : () => {
        return BFFPrivateHttpRequestHelper.getMoreServiceData()
    },
    getRookieServiceData : () => {
        return BFFPrivateHttpRequestHelper.getRookieServiceData()
    },
    deleteMoreService : () => {
        return BFFPrivateHttpRequestHelper.deleteMoreService()
    },
    deleteRookieService : () => {
        return BFFPrivateHttpRequestHelper.deleteRookieService()
    },
};

export default MemberService;