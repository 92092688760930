// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ConfirmModal_modal__2OOZ3{\n    padding: 38px 32px 32px 32px;\n    width: 428px; \n    background-color: white; \n    border: none; \n    border-radius: 20px;\n}\n.ConfirmModal_modal__2OOZ3 > .ConfirmModal_title__Ogdhh{font-size: 20px; font-weight: 700; }\n.ConfirmModal_modal__2OOZ3 > .ConfirmModal_sub-title__9wdUr{padding-top: 10px; font-size: 18px; word-break: keep-all; line-height: 24px;}\n.ConfirmModal_modal__2OOZ3 > .ConfirmModal_description__kOaJ-{padding-top: 10px; font-size: 18px; word-break: keep-all; line-height: 24px;}\n.ConfirmModal_modal__2OOZ3 > .ConfirmModal_btn-box__JCm9z{display: flex; padding-top: 30px; gap: 16px;}\n.ConfirmModal_modal__2OOZ3 > .ConfirmModal_btn-box__JCm9z > div{flex: 1 1;}", "",{"version":3,"sources":["webpack://./src/modules/Modal/ConfirmModal/ConfirmModal.module.css"],"names":[],"mappings":"AAAA;IACI,4BAA4B;IAC5B,YAAY;IACZ,uBAAuB;IACvB,YAAY;IACZ,mBAAmB;AACvB;AACA,wDAAgB,eAAe,EAAE,gBAAgB,EAAE;AACnD,4DAAoB,iBAAiB,EAAE,eAAe,EAAE,oBAAoB,EAAE,iBAAiB,CAAC;AAChG,8DAAsB,iBAAiB,EAAE,eAAe,EAAE,oBAAoB,EAAE,iBAAiB,CAAC;AAClG,0DAAkB,aAAa,EAAE,iBAAiB,EAAE,SAAS,CAAC;AAC9D,gEAAwB,SAAO,CAAC","sourcesContent":[".modal{\n    padding: 38px 32px 32px 32px;\n    width: 428px; \n    background-color: white; \n    border: none; \n    border-radius: 20px;\n}\n.modal > .title{font-size: 20px; font-weight: 700; }\n.modal > .sub-title{padding-top: 10px; font-size: 18px; word-break: keep-all; line-height: 24px;}\n.modal > .description{padding-top: 10px; font-size: 18px; word-break: keep-all; line-height: 24px;}\n.modal > .btn-box{display: flex; padding-top: 30px; gap: 16px;}\n.modal > .btn-box > div{flex: 1;}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modal": "ConfirmModal_modal__2OOZ3",
	"title": "ConfirmModal_title__Ogdhh",
	"sub-title": "ConfirmModal_sub-title__9wdUr",
	"description": "ConfirmModal_description__kOaJ-",
	"btn-box": "ConfirmModal_btn-box__JCm9z"
};
export default ___CSS_LOADER_EXPORT___;
