import React from 'react';
import axios from 'axios';
import { Cookies } from 'react-cookie';
//HTTP 통신에 대한 Get , POST , PUT , DELETE를 관장
const API_BFF_AUTH_URL = process.env.REACT_APP_API_BFF_AUTH_URL
const protocal = window.location.protocol+"//"
const BFFHttpRequestHelper = {
    findIdRequest: async (url,tokenData) => {
        const token = tokenData
        const instance = axios.create({
            baseURL : protocal + API_BFF_AUTH_URL,
            headers : {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        })
        try{
            const response = await instance.post(url)
            return response.data
        }catch(error){
            throw error
        }
    },
    resetPasswordRequest: async (url,data,tokenData) => {
        const token = tokenData
        const instance = axios.create({
            baseURL : protocal + API_BFF_AUTH_URL,
            headers : {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        })
        try{
            const response = await instance.post(url,data)
            return response.data
        }catch(error){
            throw error
        }
    },
    get: async (url,param) => {
        const instance = axios.create({
            baseURL : protocal + API_BFF_AUTH_URL,
            headers : { 'Content-Type': 'application/json' }
        })
        if(param){
            param.order = JSON.stringify(param.order)
            param.filter = JSON.stringify(param.filter)
            param.paging = JSON.stringify(param.paging)
        }
        try{
            const response = await instance.get(url,{params:param})
            return response.data
        }catch(error){
            throw error
        }
    },
    post: async (url,data) => {
        const instance = axios.create({
            baseURL : protocal + API_BFF_AUTH_URL,
            headers : { 'Content-Type': 'application/json' }
        })
        try{
            const response = await instance.post(url,data)
            return response.data
        }catch(error){
            throw error
        }
    },
    put:async (url,data)=>{
        const instance = axios.create({
            baseURL : protocal + API_BFF_AUTH_URL,
            headers : { 'Content-Type': 'application/json' }
        })
        try{
            const response = await instance.put(url,data)
            return response.data
        }catch(error){
            throw error
        }
    },
    delete:async (url)=>{
        const instance = axios.create({
            baseURL : protocal + API_BFF_AUTH_URL,
            headers : { 'Content-Type': 'application/json' }
        })
        try{
            const response = await instance.delete(url)
            return response.data
        }catch(error){
            throw error
        }
    }
    
};
export default BFFHttpRequestHelper;