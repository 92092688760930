import React from 'react';
import axios from 'axios';
//HTTP 통신에 대한 Get , POST , PUT , DELETE를 관장
const protocal = window.location.protocol+"//"
const HttpRequestHelper = {
    get: async (url,data,token) => {
        if(token){
            axios.defaults.headers.common['Authorization'] = "Bearer " + token
        }
        if(data){
            data.order = JSON.stringify(data.order)
            data.filter = JSON.stringify(data.filter)
            data.paging = JSON.stringify(data.paging)
        }
        try {
            const result = await axios.get(protocal+url, { params: data });
            return result.data;
        } catch (error) {
            throw new Error(error)
        }
    },
    post: async (url,data,temporary_token) => {
        if(temporary_token){
            axios.defaults.headers.common['Authorization'] = "Bearer " + temporary_token
        }
        const config = { headers: { 'Content-Type': 'application/json' } };
        let body = JSON.stringify(data);
        try {
            const result = await axios.post(protocal+url, body , config);
            return result.data;
        } catch (error) {
            throw error
        }
    },
    put:async (url,data,temporary_token)=>{
        if(temporary_token){
            axios.defaults.headers.common['Authorization'] = "Bearer " + temporary_token
        }
        const config = { headers: { 'Content-Type': 'application/json' } };
        let body = JSON.stringify(data);
        try {
            const result = await axios.put(protocal+url, body , config);
            return result.data;
        } catch (error) {
            throw error
        }
    },
    delete:async (url,data,temporary_token)=>{
        if(temporary_token){
            axios.defaults.headers.common['Authorization'] = "Bearer " + temporary_token
        }
        const config = { headers: { 'Content-Type': 'application/json' } };
        let body = JSON.stringify(data);
        try {
            const result = await axios.delete(protocal+url, body , config);
            return result.data;
        } catch (error) {
            throw error
        }
    }
};
export default HttpRequestHelper;