import React, { useEffect, useState } from 'react';
import checkboxDisable from '../../../assets/svgs/CheckBoxDisable.svg'
import checkboxEnable from '../../../assets/svgs/CheckBoxEnable.svg'
// import './AgreeTerms.css'
import { Link, useLocation, useNavigate } from 'react-router-dom';
import JoinPager from '../../../Layout/JoinPager/JoinPager';
import CheckDeviceService from '../../CheckDeviceService';

const NiceGuardTerms = () => {
    const location = useLocation()
    const navigate = useNavigate()
    const [privacy,setPrivacy] = useState(false)
    const [service,setService] = useState(false)
    const [uniqueIdentification,setUniqueIdentification] = useState(false)
    const [carrierTerms,setCarrierTerms] = useState(false)
    const [allchecked,setAllchecked] = useState(false)
    const [thirdParty,setThirdParty] = useState(false)
    const goToNiceValidations = () => {
        navigate("../guardcertification",
        {state:{
            name:location.state.name,
            phone: location.state.phone,
            birthday : location.state.birthday,
            cicode : location.state.cicode,
            dicode : location.state.dicode
        },replace:true})
    }
    useEffect(()=>{
        var allcheck = true
        if(!privacy){allcheck=false;}
        if(!service){allcheck=false;}
        if(!uniqueIdentification){allcheck=false;}
        if(!carrierTerms){allcheck=false;}
        if(!thirdParty){allcheck=false;}
        setAllchecked(allcheck)
    },[privacy,service,uniqueIdentification,carrierTerms,thirdParty])
    const checkAll = (state) => {
        setPrivacy(state)
        setService(state)
        setUniqueIdentification(state)
        setCarrierTerms(state)
        setThirdParty(state)
    }
    return (
        <> 
        <div className='agree'>
            <div>
                <JoinPager
                num={2}
                ></JoinPager>
                <p className='info'>보호자 본인인증 서비스 <br />이용약관 동의</p>
                <p className='sub-info'>본인인증 서비스 이용을 위한 인증 업체 약관을 동의해 주셔야 합니다. 거부 시 본인인증이 불가하여 수퍼빈 서비스를 이용하실 수 없습니다.</p>
                <div className='checkAllBtn' onClick={()=>checkAll(!allchecked)}>
                    <img src={allchecked? checkboxEnable : checkboxDisable}></img>
                        <div>
                            <p>전체동의</p>
                        </div>
                </div>
                <div className='allcheck-line'></div>
                <div className='checkBtn checkBtn-first'>
                    <img onClick={()=>{setPrivacy(!privacy);}} src={privacy? checkboxEnable : checkboxDisable}></img>
                    <div>
                        <p onClick={()=>{setPrivacy(!privacy);}}>[필수] 개인정보 이용 동의</p>
                        <a target='_blank' href="https://cert.vno.co.kr/app/agree/agree_m_01.jsp">
                            <p onClick={()=>{if(CheckDeviceService.checkMobile()){window.location.href = "https://cert.vno.co.kr/app/agree/agree_m_01.jsp"}}}>
                            보기</p>
                        </a>                    
                    </div>
                </div>
                <div className='checkBtn'>
                    <img onClick={()=>{setUniqueIdentification(!uniqueIdentification);}} src={uniqueIdentification? checkboxEnable : checkboxDisable}></img>
                    <div>
                        <p onClick={()=>{setUniqueIdentification(!uniqueIdentification);}}>[필수] 고유식별 정보처리 동의</p>
                        <a target='_blank' href="https://cert.vno.co.kr/app/agree/agree_m_02.jsp">
                            <p onClick={()=>{if(CheckDeviceService.checkMobile()){window.location.href = "https://cert.vno.co.kr/app/agree/agree_m_02.jsp"}}}>보기</p>
                        </a>                    
                    </div>
                </div>
                <div className='checkBtn'>
                    <img onClick={()=>{setService(!service);}} src={service? checkboxEnable : checkboxDisable}></img>
                    <div>
                        <p onClick={()=>{setService(!service);}}>[필수] 서비스 이용약관 동의</p>
                        <a target='_blank' href="https://cert.vno.co.kr/app/agree/agree_m_04.jsp">
                            <p onClick={()=>{if(CheckDeviceService.checkMobile()){window.location.href = "https://cert.vno.co.kr/app/agree/agree_m_04.jsp"}}}>보기</p>
                        </a>                    
                    </div>
                </div>
                {/* <div className='checkBtn' >
                    <img onClick={()=>{setThirdParty(!thirdParty);;}} src={thirdParty? checkboxEnable : checkboxDisable}></img>
                    <div>
                        <p onClick={()=>{setThirdParty(!thirdParty);}}>[필수] 개인정보 제 3자 제공 동의</p>
                        <a target='_blank' href="https://cert.vno.co.kr/app/agree/agree_m_05.jsp"><p>보기</p></a>
                    </div>
                </div> */}
                <div className='checkBtn checkBtn-last' >
                    <img onClick={()=>{setCarrierTerms(!carrierTerms);}} src={carrierTerms? checkboxEnable : checkboxDisable}></img>
                    <div>
                        <p onClick={()=>{setCarrierTerms(!carrierTerms);}}>[필수] 통신사 이용약관 동의</p>
                        <a target='_blank' href="https://cert.vno.co.kr/app/agree/agree_m_03.jsp">
                            <p onClick={()=>{if(CheckDeviceService.checkMobile()){window.location.href = "https://cert.vno.co.kr/app/agree/agree_m_03.jsp"}}}>보기</p>
                        </a>                    
                    </div>
                </div>
            </div>
            <button onClick={()=>{goToNiceValidations()}} disabled={carrierTerms && uniqueIdentification && service && privacy ? false:true}>동의하고 본인 인증하기</button>
        </div>
        </>
    );
};

export default NiceGuardTerms;