import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Modal from '../../Layout/Modals/Modal';
import AuthService from '../AuthService';
import Cryptoservice from '../Cryptoservice';
import availability_disable from '../../assets/icons/availability_disable.svg'
import availability_enable from '../../assets/icons/availability_enable.svg'
import { QueryContext } from '../../App';
import Subtract from '../../assets/icons/Subtract';

const New_pass = () => {
    const navigate = useNavigate()
    const location = useLocation()
    const query = useContext(QueryContext);
    const [passValue,setpassValue] = useState('')
    const [passStringCheck,setPassStringCheck] = useState(false)
    const [passLengthCheck,setPassLengthCheck] = useState(false)
    //알림 모달
    const [modal,setModal] = useState(false)
    const [modal_title,setmodal_title] = useState('')
    const [modal_contents,setmodal_contents] = useState('')
    //정규식
    const closeModalView = () => {
        if(modal_contents === "잘못된 접근입니다."){
            setModal(false)
            navigate("/"+query[0],{replace:true})
        }else{
            setModal(false)
        } 
    }
    const setPassword = (data) => {
        checkPassValue(data)
        setpassValue(data)
    }
    const checkPassValue = (data) => {
        const regex = /[A-Za-z0-9]/g
        const regexNumber = /[0-9]/g
        const regexString = /[A-Za-z]/g
        const pwRegex = /^[0-9a-zA-Z!@#$",./:;<=>?~[^_`\]{|()'￦₩}*&%^+*\\\-=]*$/
        if(data.length >=8 && data.length <=20){
            setPassLengthCheck(true)
        }else{
            setPassLengthCheck(false)
        }
        if(regex.test(data)){
            var countStringCorrectCount = 0
            regexString.test(data)? countStringCorrectCount++ : countStringCorrectCount = countStringCorrectCount+0
            regexNumber.test(data)? countStringCorrectCount++ : countStringCorrectCount = countStringCorrectCount+0
            if(countStringCorrectCount >= 2){
                setPassStringCheck(true)
            }else{
                setPassStringCheck(false)
            }
            if(!pwRegex.test(data)){setPassStringCheck(false)}
        }else{
            setPassStringCheck(false)
        }
    }
    const goToCheckPass = () => {
        navigate("../check",{state:{
            password:passValue,
            auth_token:location.state.auth_token
        },replace:false})
    }
    return (
        <div className='new-password-page'>
            <p className='info'>비밀번호 변경</p>
            <p className='sub-info'>새로운 비밀번호를 입력하세요.</p>
            <div className='items item-first last-item'>
                <div className='input-colum-title'>
                    <p>비밀번호</p>
                </div>
                <input type='password' className='input-colum' onChange={(event)=>{
                    const data = event.target.value
                    setPassword(data)
                }} placeholder='비밀번호를 입력해주세요'></input>
                <div className='input-colum-comment'>
                    <div>
                        <img src={passLengthCheck ?availability_enable:availability_disable} alt="avility_string"></img>
                        <p>8~20자 사이</p>
                    </div>
                    <div>
                        <img src={passStringCheck ?availability_enable:availability_disable} alt="avility_string"></img>
                        <p className='arrow_text'>영문(대소문자 구분), 숫자, 특수문자 중 2개 사용
                            <div className="subtract-box">
                                <div className="subtract">
                                    <Subtract></Subtract>
                                </div>
                                <div className="arrow_box">
                                    <p className="arrow_title">사용 가능한 특수문자 종류 안내</p>
                                    <p className="arrow_contents"> ! &#34; # $ % & &#39; ( ) * + , - . / : ; &#60; &#61; &#62; &#63; @ [ ₩ ] ^ _ ` &#123; &#124; &#125; ~</p>
                                </div>
                            </div>
                        </p>
                    </div>
                </div>
            </div>
            <button disabled = {passStringCheck && passLengthCheck ? false:true} onClick={() => goToCheckPass()}>다음</button>
            {modal? <Modal
                title={modal_title}
                contents={modal_contents}
                closeModal = {closeModalView}
                ></Modal>:<></>}
        </div>
    );
};

export default New_pass;