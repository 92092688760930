import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Modal from '../../../Layout/Modals/Modal';
import availability_disable from '../../../assets/icons/availability_disable.svg'
import availability_enable from '../../../assets/icons/availability_enable.svg'
import JoinPager from '../../../Layout/JoinPager/JoinPager';
import Subtract from '../../../assets/icons/Subtract';
const JoinPassValue = () => {
    const navigate = useNavigate()
    const location = useLocation()
    const [passValue,setpassValue] = useState('')
    const [passStringCheck,setPassStringCheck] = useState(false)
    const [passLengthCheck,setPassLengthCheck] = useState(false)
    //알림 모달
    const [modal,setModal] = useState(false)
    const [modal_title,setmodal_title] = useState('')
    const [modal_contents,setmodal_contents] = useState('')

    const closeModalView = () => {
            setModal(false)
    }
    const setPassword = (data) => {
        checkPassValue(data)
        setpassValue(data)
    }
    const checkPassValue = (data) => {
        const regex = /[A-Za-z0-9]/g
        const regexNumber = /[0-9]/g
        const regexString = /[A-Za-z]/g
        // const regexXmr = /[\{\}\[\]\/?.,;:|\)*~`!^\-_+<>@\#$%&\\\=\(\'\"]/g;
        const pwRegex = /^[0-9a-zA-Z!@#$",./:;<=>?~[^_`\]{|()'￦₩}*&%^+*\\\-=]*$/
        if(data.length >=8 && data.length <=20){
            setPassLengthCheck(true)
        }else{
            setPassLengthCheck(false)
        }
        if(regex.test(data)){
            var countStringCorrectCount = 0
            regexString.test(data)? countStringCorrectCount++ : countStringCorrectCount = countStringCorrectCount+0
            regexNumber.test(data)? countStringCorrectCount++ : countStringCorrectCount = countStringCorrectCount+0
            // regexXmr.test(data)? countStringCorrectCount++ : countStringCorrectCount = countStringCorrectCount+0
            if(countStringCorrectCount >= 2){
                setPassStringCheck(true)
            }else{
                setPassStringCheck(false)
            }
            if(!pwRegex.test(data)){setPassStringCheck(false)}
        }else{
            setPassStringCheck(false)
        }
    }
    const nextPage = () => {
        if(location.state.guardion_name){
            navigate("../check",{state:{
                id : location.state.id,
                password:passValue,
                name : location.state.name,
                birthday:location.state.birthday,
                phone:location.state.phone,
                cicode : location.state.cicode,
                dicode : location.state.dicode,
                guardion_cicode : location.state.guardion_cicode,
                guardion_dicode : location.state.guardion_dicode,
                guardion_name:location.state.guardion_name,
                guardion_birthday : location.state.guardion_birthday,
            }})
        }else{
            navigate("../check",{state:{
                id : location.state.id,
                password:passValue,
                name : location.state.name,
                birthday:location.state.birthday,
                phone:location.state.phone,
                cicode : location.state.cicode,
                dicode : location.state.dicode,
            }})
        }

    }
    return (
        <div className='join-password-page'>
            <JoinPager
                num={3}
            ></JoinPager>
            <p className='info'>비밀번호 입력(필수 정보)</p>
            <p className='sub-info'>아래 칸에 사용하실 비밀번호를 입력 후 [다음] 버튼을 눌러주세요.</p>
            <div className='items item-first last-item'>
                <div className='input-colum-title'>
                    <p>비밀번호</p>
                </div>
                <input value={passValue} maxLength={20} type='password' className='input-colum' onChange={(event)=>{
                    var data = event.target.value
                    data = data.replace(" ","")
                    setPassword(data)
                }} placeholder='비밀번호를 입력해주세요'></input>
                <div className='input-colum-comment'>
                    <div>
                        <img src={passLengthCheck ?availability_enable:availability_disable} alt="avility_string"></img>
                        <p>8~20자 사이</p>
                    </div>
                    <div>
                        <img src={passStringCheck ?availability_enable:availability_disable} alt="avility_string"></img>
                        <p>영문(대소문자 구분), 숫자, 특수문자 중 2개 사용</p>
                            <div className="subtract-box">
                                <div className="subtract">
                                    <Subtract></Subtract>
                                </div>
                                <div className="arrow_box">
                                    <p className="arrow_title">사용 가능한 특수문자 종류 안내</p>
                                    <p className="arrow_contents"> ! &#34; # $ % & &#39; ( ) * + , - . / : ; &#60; &#61; &#62; &#63; @ [ ₩ ] ^ _ ` &#123; &#124; &#125; ~</p>
                                </div>
                            </div>
                        
                    </div>
                </div>
            </div>
            <button disabled = {passStringCheck && passLengthCheck ? false:true} onClick={() => nextPage()}>다음</button>
            {modal? <Modal
                title={modal_title}
                contents={modal_contents}
                closeModal = {closeModalView}
                ></Modal>:<></>}
        </div>
    );
};

export default JoinPassValue;