import React from 'react';
import DaumPostCode from 'react-daum-postcode'
const Postcode = ({postComplete,postCancel}) => {
    const cancel = () => {
      postCancel()
    }
    const complete = (dd) =>{
        postComplete(dd)
    }
    const handleComplete = (data) => {
      let fullAddress = data.address;
      let extraAddress = ''; 
      
      if (data.addressType === 'R') {
        if (data.bname !== '') {
          extraAddress += data.bname;
        }
        if (data.buildingName !== '') {
          extraAddress += (extraAddress !== '' ? `, ${data.buildingName}` : data.buildingName);
        }
        fullAddress += (extraAddress !== '' ? ` (${extraAddress})` : '');
      }
      data.fullAddress = fullAddress
      complete(data)
    }
    return (
      <div>
        <DaumPostCode className='postcode'
          onComplete={handleComplete}
        />
      </div>
    );
  }

export default Postcode;