import React, { useEffect, useState } from 'react';
import checkboxDisable from '../../assets/svgs/CheckBoxDisable.svg'
import checkboxEnable from '../../assets/svgs/CheckBoxEnable.svg'
import './AgreeTerms.css'
import { Link, useLocation, useNavigate } from 'react-router-dom';
import JoinPager from '../../Layout/JoinPager/JoinPager';
import { QueryContext } from '../../App';
import { useContext } from 'react';
const AgreeTerms = () => {
    const location = useLocation();
    const queryParam = new URLSearchParams(location.search)
    const navigate = useNavigate()
    const [serviceCheck,setserviceCheck] = useState(false)
    const [personCheck,setpersonCheck] = useState(false)
    const [withdrawCheck,setWithdrawCheck] = useState(false)
    useEffect(()=>{
        if(!sessionStorage.getItem('byLogin')){
            sessionStorage.removeItem('redirectInfo')
        }
    })
    const saveRedirect = () => {
        if(!sessionStorage.getItem('byLogin')){
            sessionStorage.setItem('redirectInfo',queryParam.get('redirect'))
        }
    }
    const goToIdValue = () => {
        navigate("niceterms",{state:{
        },replace:true})
    }
    return (
        <> 
        <div className='agree'>
            <div>
                <JoinPager
                num={1}
                ></JoinPager>
                <p className='info'>수퍼빈 서비스 이용약관 동의</p>
                <p className='sub-info'>수퍼빈 회원이 되기 위해서는 ‘수퍼빈 서비스 이용약관’과 ‘개인정보 수집 · 이용 동의’에 동의해 주셔야 합니다. 체크 후 [동의하고 본인 인증하기]를 눌러주세요.</p>
                <div className='checkBtn'>
                    <img onClick={()=>setserviceCheck(!serviceCheck)} src={serviceCheck? checkboxEnable : checkboxDisable}></img>
                    <div>
                        <p onClick={()=>setserviceCheck(!serviceCheck)}>[필수] 수퍼빈 서비스 이용약관 동의</p>
                        <Link to = "terms/service"><p>보기</p></Link>
                    </div>
                </div>
                <div className='checkBtn' >
                    <img onClick={()=>setpersonCheck(!personCheck)} src={personCheck? checkboxEnable : checkboxDisable}></img>
                    <div>
                        <p onClick={()=>setpersonCheck(!personCheck)}>[필수] 개인정보 수집 · 이용 동의</p>
                        <Link to = "terms/personal"><p>보기</p></Link>
                    </div>
                </div>
                <div className='checkBtn checkBtn-last' >
                    <img onClick={()=>setWithdrawCheck(!withdrawCheck)} src={withdrawCheck? checkboxEnable : checkboxDisable}></img>
                    <div>
                        <p onClick={()=>setWithdrawCheck(!withdrawCheck)}>[필수] 회원가입한 본인 명의의 계좌로만 포인트 현금 환전이 가능함을 동의 </p>
                    </div>
                </div>
            </div>
            <button onClick={()=>{saveRedirect();goToIdValue()}} disabled={serviceCheck && personCheck && withdrawCheck ? false:true}>동의하고 본인 인증하기</button>
            <div className='description-bottom'>
                <p>※ 주의사항</p>
                <p>통신사 정책으로 인해 법인 명의의 휴대전화 번호로는 본인 인증이 되지 않아 가입이 불가할 수 있습니다.</p>
            </div>
        </div>
        </>
    );
};
export default AgreeTerms;