import React from 'react';
import style from './FullWidthBtn.module.css'
const FullWidthBtn = ({onClick,title,disabled}) => {
    return (
        <div className={style["btn-box"]}>
            <button disabled={disabled} onClick={onClick}>{title}</button>
        </div>
    );
};

export default FullWidthBtn;