import React, { useEffect } from 'react';
import { useContext } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { QueryContext } from '../../App';
const FindPassSuccess = () => {
    const navigate = useNavigate()
    const query = useContext(QueryContext);
    useEffect(()=>{
    },[])
    return (
        <div className='find-id-success-page'>
            <div className='success-logo'>
                <svg className='changedone' width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="40" cy="40" r="40" fill="#F2F4F7"/>
                <rect width="8.79561" height="22.3545" rx="3" transform="matrix(0.709405 -0.704801 0.709405 0.704801 19.168 40.2754)" fill="#3997FE"/>
                <rect width="8.79561" height="36.113" rx="3" transform="matrix(0.709482 0.704724 -0.709482 0.704724 54.5898 24.5088)" fill="#3997FE"/>
                </svg>
            </div>
            <div className='items item-none'>
                <p className='info'>비밀번호 변경 완료</p>
                <p className='sub-info'>비밀번호 변경이 완료되었습니다.</p>
            </div>
            <button onClick={() =>{
                const redirectItem = sessionStorage.getItem('redirectInfo')
                if(redirectItem === "null" || redirectItem === null){
                    window.location.replace("http://www.superbin.co.kr")
                }else{
                    if(redirectItem.startsWith("http://") || redirectItem.startsWith("https://")){
                        window.location.replace(redirectItem)
                    }else{
                        window.location.replace("http://"+redirectItem)
                    }
                }
            }}>로그인하기</button>
        </div>
    );
};

export default FindPassSuccess;