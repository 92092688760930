import React from 'react';
import HttpRequestHelper from '../../Helper/HttpRequestHelper';
import HttpRequestHelperNotProtocol from '../../Helper/HttpRequestHelperNotProtocol';
import BFFHttpRequestHelper from '../../Helper/BFFHttpRequestHelper';
const ServiceUrl = process.env.REACT_APP_API_ENTITY_CMS_URL
const NiceUrl = process.env.REACT_APP_API_ENTITY_NICE_AUTH_URL
const JoinUsService =  {

    getAgreeTerm:() => {
        return BFFHttpRequestHelper.get("/terms/service",undefined)
    },
    getPrivateTerm:() => {
        return BFFHttpRequestHelper.get("/terms/privacy",undefined)
    },
    postNiceIdSMS:(body) => {
        return BFFHttpRequestHelper.post("/phone-identification/request",body)
    },
    postNiceIdSMSValidation:(body) => {
        return BFFHttpRequestHelper.post("/phone-identification/verify",body)
    },
    postGuardianNiceIdSMSValidation:(body) => {
        return BFFHttpRequestHelper.post("/phone-identification/verify-for-guardian",body)
    }
};

export default JoinUsService;