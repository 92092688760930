import React from 'react';
import './Assitance.css';
import kakaoLogo from '../../assets/icons/kakao_assitance_logo.png'
// import footerLogo from '../../assets/icons/footer_logo.png'
import footerLogo from '../../assets/svgs/footerLogo.svg'
import goKakao from '../../assets/icons/right_compact.png'
import { useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useState } from 'react';
import Svgs from '../../assets/icons/svgs';
const Assitance = () => {
    const location = useLocation()
    const [kakaoOn,setKakaoOn] = useState(false)
    useEffect(()=>{
        if(location.pathname.startsWith('/login')){
            setKakaoOn(true)
        }else{
            setKakaoOn(false)
        }
    })
    return (
        <div className='footer-assitance'>
            {kakaoOn?
            <div className='kakao'>
                <svg className='kakao-logo' width="16" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M2.16046 14.7461C2.96339 13.8461 3.71573 12.9164 3.99561 11.6816C3.12496 11.201 2.35385 10.596 1.74023 9.80796C-0.0875854 7.45946 0.124571 4.48862 2.27062 2.38293C3.6937 0.988252 5.4293 0.27061 7.37135 0.0650956C9.82176 -0.194283 12.0902 0.307901 14.1065 1.81611C16.3309 3.48011 17.1142 6.21063 15.998 8.62874C15.293 10.1552 14.0828 11.1745 12.6035 11.8838C10.9519 12.6752 9.20977 12.8932 7.40399 12.7415C7.18531 12.7233 6.92256 12.7887 6.7357 12.9064C5.44644 13.7111 4.07884 14.3309 2.63373 14.7792C2.5097 14.8174 2.37833 14.8315 2.25022 14.8571L2.16209 14.7469L2.16046 14.7461Z" fill="black"/>
                </svg>
                <a href='https://pf.kakao.com/_IxmbLxb'>카카오톡 채널 상담하기</a>
                <svg width="12" height="19" viewBox="0 0 12 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M5.04737 4.74998L9.59251 9.49989L5.04737 14.2498" stroke="#222222" strokeWidth="1.5"/>
                </svg>
            </div>:<></>}
            <div>
                <img className='footer-logo' src={footerLogo}></img>
                <p className='slice'>&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;</p>
                <a target={'_blank'} href='/join/terms/service'><p>이용약관</p></a>
                <p className='slice'>&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;</p>
                <a target={'_blank'} href='/join/terms/personal'><p>개인정보 처리방침</p></a>
            </div>
            <div>
                <a>©2015.SUPERBIN inc. all rights reserved.</a>
            </div>
        </div>
    );
};

export default Assitance;