import React from 'react';

const RecertificationVerificationViewModel = {
    checkNameData : (data) => {
        const check_eng = /[a-zA-Z]/;    // 문자 
        const check_kor = /[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/; // 한글체크
        let trimString = data.trim()
        if(check_kor.test(trimString) && check_eng.test(trimString)){
            return false
        }else{
            if(check_kor.test(trimString)){trimString = trimString.replace(/ /gi,"")}
            return trimString
        }
    },
    replaceIdCardData : (data) => {
        let replaceData = data.replace(/[^0-9]/g, '')
        replaceData = replaceData.replace(/^(\d{3,4})(\d{4})$/, `$1-$2`)
        if(!replaceData){
            return undefined
        }
        if(replaceData.length < 7){
            return replaceData
        }else{
            return false
        }
    },
    replaceSexNumberData : (data) => {
        let replaceData = data.replace(/[^0-9]/g, '')
        if(replaceData.length < 2){
            return replaceData
        }else{
            return false
        }
    },
};
export default RecertificationVerificationViewModel;