import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Modal from '../../../Layout/Modals/Modal';
import availability_disable from '../../../assets/icons/availability_disable.svg'
import availability_enable from '../../../assets/icons/availability_enable.svg'
import JoinPager from '../../../Layout/JoinPager/JoinPager';

const SexValue = () => {
    const navigate = useNavigate()
    const location = useLocation()
    const [sexValue , setSexVakye] = useState(null)
    //알림 모달
    const [modal,setModal] = useState(false)
    const [modal_title,setmodal_title] = useState('')
    const [modal_contents,setmodal_contents] = useState('')
    const closeModalView = () => {
            setModal(false)
    }
    const replaceSexValue = (data) => {
        var value = null
        if(data == 0){value = "M"}
        if(data == 1){value = "F"}
        if(data == 2){value = "N"}
        return value
    }
    const nextPage = () => {
        if(location.state.guardion_name){
            navigate("../optional",{state:{
                id : location.state.id,
                password:location.state.password,
                name : location.state.name,
                sex:replaceSexValue(sexValue),
                birthday:location.state.birthday,
                phone:location.state.phone,
                cicode : location.state.cicode,
                dicode : location.state.dicode,
                guardion_cicode : location.state.guardion_cicode,
                guardion_dicode : location.state.guardion_dicode,
                guardion_name:location.state.guardion_name,
                guardion_birthday : location.state.guardion_birthday,
            },replace:true})
        }else{
            navigate("../optional",{state:{
                id : location.state.id,
                password:location.state.password,
                name : location.state.name,
                sex:replaceSexValue(sexValue),
                birthday:location.state.birthday,
                phone:location.state.phone,
                cicode : location.state.cicode,
                dicode : location.state.dicode,
            },replace:true})
        }
    }
    return (
        <div className='join-password-page'>
            <JoinPager
                num={3}
            ></JoinPager>
            <p className='info'>성별 선택(필수 정보)</p>
            <p className='sub-info'>본인의 성별을 선택해주세요.</p>
            <div className='items item-first last-item'>
                <div className='input-colum-sex-select'>
                    <ul>
                        <li><button className={sexValue == 0 ? "act":"non-act"} onClick={()=>{setSexVakye(0)}}>남성</button></li>
                        <li><button className={sexValue == 1 ? "act":"non-act"} onClick={()=>{setSexVakye(1)}}>여성</button></li>
                        <li><button className={sexValue == 2 ? "act":"non-act"} onClick={()=>{setSexVakye(2)}}>선택 안함</button></li>
                    </ul>
                </div>
            </div>
            <button disabled = {sexValue == undefined? true:false} onClick={() => nextPage()}>다음</button>
            {modal? <Modal
                title={modal_title}
                contents={modal_contents}
                closeModal = {closeModalView}
                ></Modal>:<></>}
        </div>
    );
};

export default SexValue;