import Login from './Auth/Login/Login';
import { Route , Routes ,Navigate} from 'react-router-dom';
import './App.css';
import UserRouter from './Routers/UserRouter';
import JoinRouter from './Routers/JoinRouter';
import Hnb from './Layout/Hnb/hnb';
import Assitance from './Layout/Assitance/Assitance';
import { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { createContext } from 'react';
import { useContext } from 'react';
import Logout from './Auth/Logout/Logout';
import QueryGuardRouter from './Routers/QueryGuardRouter';
import IosBack from './Layout/IosBack/IosBack';
import ProtectRoute from './Routers/ProtectRoute';
import Withdraw from './Auth/Withdraw/Withdraw';
import { AuthProvider } from './Auth/AuthProvider';
import CheckDeviceService from './Auth/CheckDeviceService';
import RecertificationRouter from './Routers/RecertificationRouter';
import AlertDialog from './modules/Modal/AlertModal/AlertDialog'
import ConfirmDialog from './modules/Modal/ConfirmModal/ConfirmDialog'
import WithdrawRouter from './Routers/WithdrawRouter';
export const QueryContext = createContext();
function QueryContextPrivider({children}){
  const location = useLocation()
  const queryState = useState(location.search);
  return (
    <QueryContext.Provider value={queryState}>
      {children}
    </QueryContext.Provider>
  );
}
function App({}) {
  const location = useLocation()
  return (
    <QueryContextPrivider>
      <QueryGuardRouter>
        
        <div className='app-root'>
          <div className={location.pathname.startsWith("/join/terms")? 'box-terms':'box'}>
            <div className='box-cont'>
              <AlertDialog>
                <ConfirmDialog>
                  <Hnb></Hnb>
                  <div className='page'>
                    <div className='contents'>
                      <Routes>
                        <Route path="/" element={<Navigate replace to="/login"/>}></Route>
                        <Route path='/logout' element={<Logout></Logout>}></Route>
                        <Route path='/login' element={<Login></Login>}></Route>
                        <Route path='/join/*' element={<JoinRouter></JoinRouter>}></Route>
                        <Route path='/recertification/*' element={<RecertificationRouter></RecertificationRouter>}></Route>
                        {/* <Route path='/user/*' element={<UserRouter></UserRouter>}></Route> */}
                        <Route path='/find-account/*' element={<UserRouter></UserRouter>}></Route>
                        <Route path='/withdraw/*' element={
                        <AuthProvider>
                          <ProtectRoute>
                            <WithdrawRouter>
                            </WithdrawRouter>
                          </ProtectRoute>
                        </AuthProvider>
                      }></Route>
                        {/* <Route path='/withdraw' element={<AuthProvider><ProtectRoute><Withdraw></Withdraw></ProtectRoute></AuthProvider>}></Route> */}
                        
                        <Route path="*" element={<Navigate replace to="/login"/>}></Route>
                      </Routes>
                    </div>
                  </div>
                </ConfirmDialog>
              </AlertDialog>
              {location.pathname.startsWith("/join/terms") || location.pathname.startsWith("/with")? <></>:CheckDeviceService.checkMobile()? <></>:<Assitance></Assitance>}
            </div>
          </div>
        </div>
      </QueryGuardRouter>
    </QueryContextPrivider>
    
  );
}

export default App;
