import React, { createContext, useContext, useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate} from 'react-router-dom';
import Modal from '../../Layout/Modals/Modal';
import AuthService from '../AuthService';
import inputright from '../../assets/icons/input-right.svg'
import './FindId.css'
import { QueryContext } from '../../App';
const FindId = () => {
    const navigate = useNavigate()
    const location = useLocation();
    const queryParam = new URLSearchParams(location.search)
    const [smsBtn,setSmsBtn] = useState(true)
    const [name,setName] = useState("")
    const [phone,setPhone] = useState("")
    const [phoneHead,setPhoneHead] = useState("")
    const [device,setDevice] = useState(false)
    //알림 모달
    const [modal,setModal] = useState(false)
    const [modalContents,setModalContents] = useState({
        title:"",
        contents:""
    })
    const [selectView,setSelectView] = useState(false)
    useEffect(()=>{
        if(!sessionStorage.getItem('byLogin')){
            sessionStorage.removeItem('redirectInfo')
        }
    },[])
    const saveRedirect = () => {
        if(!sessionStorage.getItem('byLogin')){
            sessionStorage.setItem('redirectInfo',queryParam.get('redirect'))
        }
    }
    const selectRef = useRef()
    // 인증 관련
    //핸드폰 input 관련
    const [inputFocus,setInputFocus] = useState(false)
    //정규 체크
    useEffect(()=>{
        const user = navigator.userAgent
        if(user.indexOf('iPhone') > -1){
            setDevice(true)
        }else if(user.indexOf('Android') > -1){
            setDevice(true)
        }else{
            setDevice(false)    
        }
    },[])
    useEffect(()=>{
        if(checkValueIn()){setSmsBtn(false)}else{setSmsBtn(true)}
    },[name,phone])
    const closeModalView = () => {
        setModal(false)
    }
    //이름 , 전화번호 확인
    const checkValueIn = () =>{
        if(!name){
            return false
        }else if(!phone){
            return false
        }else{
            return true
        }
    }
    //핸드폰 인증 번호 조회
    const certificationPhone = async () => {
        const data = {
            name:name,
            phone_no: phoneHead? phoneHead +phone.replace('-','') :'010' + phone.replace('-','')
        }
        try{
            const result = await AuthService.sendSMSCodeForFindId(data)
            saveRedirect()
            navigate("sms",{state:{
                last:'find-id',
                name:name,
                phone:phoneHead? phoneHead +phone.replace('-','') :'010'+phone.replace('-',''),
                key : result.key
            }})
        }catch(error){
            if(error.response.status === 404){
                setModalContents({
                    title:'알림',
                    contents:'일치하는 정보가 없습니다. 가입된 본인 이름과 휴대전화 번호를 다시 확인해 주세요.'
                })
                setModal(true)
            }else if(error.response.status === 401){
                setModalContents({
                    title:'알림',
                    contents:'일치하는 정보가 없습니다. 가입된 본인 이름과 휴대전화 번호를 다시 확인해 주세요.'
                })
                setModal(true)
            }else if(error.response.status === 409){
                setModalContents({
                    title:'알림',
                    contents:'일치하는 정보가 없습니다. 가입된 본인 이름과 휴대전화 번호를 다시 확인해 주세요.'
                })
                setModal(true)
            }else{
                setModalContents({
                    title:'알림',
                    contents:'기타 오류입니다. 지속시 관리자에게 문의해주세요.'
                })
                setModal(true)
            }
        }
    }
    
    return (
        <>
        <div className='find-id-page'>
            <p className='info'>아이디를 찾기 위한 정보 입력</p>
            <p className='sub-info'>아이디를 분실하신 경우 아이디를 찾을 수 있는 페이지입니다. 이름과 휴대전화 번호를 입력해주세요.</p>
            <div className='items item-first'>
                <div className='input-colum-title'>
                    <p>이름</p>
                </div> 
                <input type='text' value={name} className='input-colum' onChange={(event)=>{
                    const data = event.target.value
                    setName(data)
                }} onBlur={()=>{setName(name.trim())}}
                placeholder='이름을 입력해주세요'></input>
                <div className='input-colum-comment'>
                    <p>수퍼빈에 가입된 본인 이름을 입력해주세요 </p>
                </div>
            </div>
            <div className='items last-item'>
                <div className='input-colum-title'>
                    <p>휴대전화 번호</p>
                </div>
                <div className='input-double'>
                    {!device? <div 
                    onClick={() => {setSelectView(!selectView)}} className={phoneHead? "selector selected":"selector"} ref={selectRef}>
                        {phoneHead? phoneHead:'010'} &nbsp;&nbsp;&nbsp;
                        <img src={inputright} alt='dropdown'></img>
                        {selectView ? <ul className="phone-list">
                            <li onClick={()=>{setPhoneHead('010')}} className="phone-item"><p>010</p></li>
                            <li onClick={()=>{setPhoneHead('011')}} className="phone-item"><p>011</p></li>
                            <li onClick={()=>{setPhoneHead('019')}} className="phone-item"><p>019</p></li>
                        </ul>:<></>}
                    </div>:<select onChange={(event)=>{
                        setPhoneHead(event.target.value)
                    }} className='input-selector'>
                        <option value='010'>010&nbsp;&nbsp;&nbsp;</option>
                        <option value='011'>011&nbsp;&nbsp;&nbsp;</option>
                        <option value='019'>019&nbsp;&nbsp;&nbsp;</option>
                    </select>}        
                    <input type='text' value={phone} className='input-colum' onChange={(event)=>{
                        var data = event.target.value
                        data = data.replace(/[^0-9]/g, '')
                        data = data.replace(/^(\d{3,4})(\d{4})$/, `$1-$2`)
                        setPhone(data)}}
                        onFocus = {()=>setInputFocus(true)}
                        onBlur = {()=>setInputFocus(false)} 
                        placeholder='휴대전화 번호를 입력해주세요'></input>
                </div>
                <hr className={inputFocus? "input-focus-true":"input-focus-false"}></hr>
                <div className='input-colum-comment'>
                    <p>휴대전화 번호는 숫자로만 입력해주세요.{inputFocus}</p>
                </div>
            </div>
            <button disabled = {smsBtn} onClick={() => certificationPhone('start')}>인증 문자 받기</button>
            {modal? <Modal
                title={modalContents.title}
                contents={modalContents.contents}
                closeModal = {closeModalView}
                ></Modal>:<></>}
        </div>
        </>
    );
};
export default FindId;