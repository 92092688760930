import React, { useContext, useEffect } from 'react';
import { QueryContext } from '../../../App';
import { useLocation, useNavigate } from 'react-router-dom';
import AlertContext from '../../../modules/Modal/AlertModal/AlertContext';
import AuthService from '../../AuthService';

const FindIdSmsCheckViewModel =  {
    FindIdSmsCheckHook : () => {
        const {alert : alertComp} = useContext(AlertContext)
        const query = useContext(QueryContext);
        const location = useLocation()
        const navigate = useNavigate()
        useEffect(()=>{
            if(!checkNotNormalEnter()){
                navigate("/"+query[0],{replace:true})
            }
        },[])
        const checkNotNormalEnter =( ) => {
            //이전 페이지 정보가 없다. 죽여라
            if(!location?.state?.last){
                return false
            }else{
                return true
            }
        }
        const checkValueEnable = (data) => {
            if(data){
                verificateSms(data)
            }else{
                alertComp('알림', '인증 번호를 입력해주세요',undefined,'확인')
            }
        }
        const verificateSms = async(data) => {
            const body = {
                key : location.state.key,
                auth_code : data.code,
            }
            try{
                const result = await AuthService.verificationFindIdBySms(body)
                findIdRequest(result)
            }catch(err){
                if(err.response?.status === 401){
                    await alertComp('알림', '인증번호가 잘못되었습니다. 다시 확인해주세요.',undefined,'확인')
                }else{
                    console.error(err)
                    await alertComp('오류 발생', '이용에 불편을 드려 죄송합니다. 서버 오류가 발생하였습니다. 계속해서 문제가 발생하는 경우 수퍼빈 카카오톡 채널로 문의 바랍니다.',undefined,'메인으로 돌아가기')
                    navigate("/"+query[0],{replace:true})    
                }
            }
        }
        const findIdRequest = async(data) => {
            try{
                const result = await AuthService.findIdByAuthVerification(data.access_token)
                navigate("../success",{state:{
                    id:result.id,
                    regular_reg_at : result.regular_reg_at,
                    access_data:"pending",
                },replace:true})
            }catch(err){
                console.error(err)
                await alertComp('오류 발생', '이용에 불편을 드려 죄송합니다. 서버 오류가 발생하였습니다. 계속해서 문제가 발생하는 경우 수퍼빈 카카오톡 채널로 문의 바랍니다.',undefined,'메인으로 돌아가기')
                navigate("/"+query[0],{replace:true})
            }
        }
        const reSendSms = async() => {
            const body = {
                key : location.state.key
            }
            try{
                const result = await AuthService.resendSMSCodeForFindId(body)
            }catch(err){
                console.error(err)
                await alertComp('오류 발생', '이용에 불편을 드려 죄송합니다. 서버 오류가 발생하였습니다. 계속해서 문제가 발생하는 경우 수퍼빈 카카오톡 채널로 문의 바랍니다.',undefined,'메인으로 돌아가기')
                navigate("/"+query[0],{replace:true})
            }
        }
        return {reSendSms,checkValueEnable}
    }
};

export default FindIdSmsCheckViewModel;