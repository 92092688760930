import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import DualModal from '../../Layout/Modals/WithDrawModal/DualModal/DualModal';
import SingleModal from '../../Layout/Modals/WithDrawModal/SingleModal/SingleModal';
import AgreeTerm from '../../modules/AgreeTerm/AgreeTerm';
import Input from '../../modules/Input/Input';
import { useAuth } from '../AuthProvider';
import AuthService from '../AuthService';
import Cryptoservice from '../Cryptoservice';
import MemberService from '../MemberService';
import PointService from '../PointService';
import style from './Withdraw.module.css'
const Withdraw = () => {
    const location = useLocation()
    const navigate = useNavigate()
    const queryParam = new URLSearchParams(location.search)
    const [accountDetail,setAccountDetail] = useState()
    const [userMore,setUserMore] = useState()
    const [userRookie,setUserRookie] = useState()
    const [password,setPassword] = useState("")
    const [privateTerms,setPrivateTerms]  = useState(false)
    const [exchangeTerms,setExchangeTerms]  = useState(false)
    const [id,setId] = useState("")
    const [modal,setModal] = useState(false)
    const [dualModal,setDualModal] = useState(false)
    const [modalContents,setModalContents] = useState({
        title:"",
        btnTitle:"",
        code:0,
    })
    const [multiModalContents,setMultiModalContents] = useState({
        title:"",
        btnTitle:"",
        btnTwoTitle:"",
    })
    const [btnEnable,setBtnEnable] = useState(false)
    const {user} = useAuth()
    useEffect(()=>{
        checkBtnEnable()
    },[id,password,privateTerms,exchangeTerms])

    const checkBtnEnable = async() => {
        setBtnEnable(await checkValues())
    }
    const checkValues = async() => {
        var valueCheck = false
        if(id && password && privateTerms && exchangeTerms){
            //모든 해지 동의 함
            valueCheck = true
        }else{
            //모든 해지 동의 안함.
            valueCheck = false
        }
        
        return valueCheck
    }
    const checkId = () => {
        if(id !== user.id){
            setModalContents({
                title:"아이디/비밀번호가 일치하지 않습니다",
                btnTitle:"확인",
                code:1
            })
            setModal(true)
            return false
        }else{
            return true
        }
    }
    const onClickNext = async() => {
        const checkTermValue = await checkValues()
        const checkIdValue = checkId()
         //재확인
         if(checkTermValue && checkIdValue){
            setMultiModalContents({
                title:"정말로 탈퇴하시겠습니까? 탈퇴 시  포인트 소멸 및 환전 신청 내역도 사라지며 포인트 현금 전환이 불가합니다.",
                btnTitle:"탈퇴",
                btnTwoTitle:"취소"
            })
            setDualModal(true)
         }
    }
    const modalController = (code) => {
        setModal(false)
        setDualModal(false)
        if(code == 2){
            if(queryParam.get('cancel_url').startsWith("http://") || queryParam.get('cancel_url').startsWith("https://")){
                window.location.replace(queryParam.get('cancel_url'))
            }else{
                window.location.replace("http://"+queryParam.get('cancel_url'))
            }
        }
    }
    const withDrawService = async() => {
        try{
            const body = {
                id: id,
                password:Cryptoservice.encrypt(password),
                remove_privacy_and_point: true,
                expire_refund_service: true
              }
            const result = await MemberService.withDrawAccount(body)
            if(queryParam.get('success_url').startsWith("http://") || queryParam.get('success_url').startsWith("https://")){
                window.location.replace(queryParam.get('success_url'))
            }else{
                window.location.replace("http://"+queryParam.get('success_url'))
            }
        }catch(error){
            //기타 에러
            if(error.response.status == 401){
                setDualModal(false)
                setModalContents({
                    title:"세션이 만료되었습니다 다시 시도해주세요",
                    btnTitle:"확인",
                    code:2
                })
                setModal(true)
            }else if(error.response.status == 404){
                setDualModal(false)
                setModalContents({
                    title:"이미 탈퇴된 회원입니다.",
                    btnTitle:"확인",
                    code:2
                })
                setModal(true)
            }else if(error.response.status == 500){
                setDualModal(false)
                setModalContents({
                    title:"이용에 불편을 드려 죄송합니다. 서버 오류가 발생하였습니다. 계속해서 문제가 발생하는 경우 수퍼빈 카카오톡 채널로 문의 바랍니다.",
                    btnTitle:"확인",
                    code:1
                })
                setModal(true)
            }else{
                setDualModal(false)
                setModalContents({
                    title:"이용에 불편을 드려 죄송합니다. 서버 오류가 발생하였습니다. 계속해서 문제가 발생하는 경우 수퍼빈 카카오톡 채널로 문의 바랍니다.",
                    btnTitle:"확인",
                    code:1
                })
                setModal(true)
            }
        }
    }
    const withDrawCancelReplace = () => {
        if(queryParam.get('cancel_url').startsWith("http://") || queryParam.get('cancel_url').startsWith("https://")){
            window.location.replace(queryParam.get('cancel_url'))
        }else{
            window.location.replace("http://"+queryParam.get('cancel_url'))
        }
    }   
    return (
        <div className={style["page-start"]}>
            <div className={style.root}>
                <div className={style.title}>회원탈퇴</div>
                <div className={style["info-box"]}>
                    <div>
                        <p>{user.nickname}님의 보유 포인트</p>
                        <p>{user.point_account.balance.toLocaleString()? user.point_account.balance.toLocaleString():0}P</p>
                    </div>
                    <hr></hr>
                    <div>
                        <p>탈퇴 주의사항</p>
                        <p>탈퇴 주의사항
                        회원 탈퇴 시 현재 이용 중인 추가 서비스(수퍼빈루키, 
                        수퍼빈모아 등)도 이용이 불가하며, 회사가 보유한 모든 
                        개인정보는 즉시 삭제됩니다. 
                        보유한 포인트(씨앗 포함) 또한 모두 소멸됩니다. 
                        <br/><br/>    
                        포인트는 탈퇴와 동시에 즉시 소멸되므로, 잔여 포인트 
                        환전 신청 및 환전 완료 후 탈퇴하시길 권장 드립니다. 
                        <br/><br/>     
                        단, &#60;수퍼빈 이용약관&#62;에 의거하여 2,000포인트 미만의 잔여 포인트는 환전이 불가합니다. 
                        <br/><br/>     
                        보유한 포인트는 10포인트 단위까지만 환전이 
                        가능합니다. (예: 2,895포인트 중 2,890포인트만 
                        신청 가능) 
                        <br/><br/>
                        회원 탈퇴 후에는 보유한 개인정보가 모두 삭제되어 
                        환전이 불가하며 회사는 탈퇴한 회원의 포인트를 복구할 수 없습니다. 
                        <br/><br/>
                        아래 사항을 전체 동의하지 않을 경우 탈퇴는 불가하며, 
                        궁금한 사항은 수퍼빈 카카오톡 채널 혹은 대표번호로 
                        문의 바랍니다.</p>
                    </div>
                </div>
                <div className={style.space}></div>
                <Input type='text'  value={id} title={'아이디'} holder={'아이디를 입력해주세요'} 
                InputData={function (value) {
                    setId(value)
                } }></Input>
                <div className={style.space}></div>
                <Input type='password' value={password} title={'비밀번호'} holder={'비밀번호를 입력해주세요'} 
                InputData={function (value) {
                    setPassword(value)
                } }></Input>
                <div className={style.space}></div>
                <AgreeTerm data={privateTerms} contents={'포인트 소멸 및 개인정보 삭제에 동의합니다.'}  
                onClickTerms={function (value) {
                    setPrivateTerms(value)
                } }></AgreeTerm>
                <AgreeTerm data={exchangeTerms} contents={'탈퇴 후 포인트 환전 불가에 동의합니다.'}  
                onClickTerms={function (value) {setExchangeTerms(value)} }></AgreeTerm>
                <div className={style["bottom-container-box"]}>
                    <button className={style["btn-normal"]} onClick={()=>{ 
                        withDrawCancelReplace()
                        }}>취소하기</button>
                    <button className={style[btnEnable? "btn-enable":"btn-disable"]} onClick={()=>{
                        if(btnEnable){
                            onClickNext()
                        }
                        }}>탈퇴하기</button>
                </div>
            </div>
            {modal? <SingleModal title={modalContents.title} btnTitle={modalContents.btnTitle} onOkClick={function (value) {
                modalController(value)
            }} code={modalContents.code}></SingleModal>:<></>}
            {dualModal? <DualModal title={multiModalContents.title} btnTitle={multiModalContents.btnTitle} btnTwoTitle={multiModalContents.btnTwoTitle} onOkClick={function () {
                withDrawService()
            }} onCancel={function () {
                modalController(0)
            }}></DualModal>:<></>}
        </div>
    );
};
export default Withdraw;