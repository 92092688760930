import React from 'react';

const CheckDeviceService =  {
    checkMobile:()=>{
        const mobile = (/iphone|ipad|ipod|android/i.test(navigator.userAgent.toLowerCase()));
        if (mobile) {
            return true
        }else{
            return false
        }
    }
};

export default CheckDeviceService;